import * as React from "react";
import {
  Box,
  Button,
  Center,
  FormControl,
  Input,
  Pressable,
  Text,
  View,
  WarningOutlineIcon,
  useToast,
  Modal,
  Spinner,
} from "native-base";
import styles from "./styles";
import { useEffect, useState } from "react";
import { Email, Phone } from "../../assets/images/svg";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "../../config/constants";
import PhoneInput from "react-phone-number-input/input";
import { toastErrorConfig } from "../../config/constants";
import { checkUser } from "../../networking/auth/authApi";
import { verifyCredential } from "../../networking/auth/authApi";

export default function SignInScreen() {
  const location = useLocation();
  const navigate = useNavigate();
  const type = location?.pathname;
  const dispatch = useDispatch();
  const toast = useToast();
  const { userToken } = useSelector((state) => state.auth);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [isPhoneFocused, setIsPhoneFocused] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (userToken) {
      navigate("/orders");
    }
  }, [userToken]);

  const goToSignUp = () => {
    if (type === "/signin/email") {
      navigate("/signup/email");
    } else {
      navigate("/signup/phone");
    }
  };

  const confirmCode = async () => {
    const verificationData = {};
    let dataType = "";
    if (type.includes("email")) {
      const englishOnlyRegex = /^[\x00-\x7F]+$/; // Matches only ASCII characters (English letters and standard symbols)
      if (!englishOnlyRegex.test(email)) {
        toast.show({
          description: "Email must contain English characters only",
          ...toastErrorConfig,
        });
        return;
      }
      if (!email.includes("@") || !email.includes(".")) {
        toast.show({
          description: "Email is invalid",
          ...toastErrorConfig,
        });
        return;
      }
      verificationData.email = email;
      dataType = "email";
    }

    if (type.includes("phone")) {
      const isValidPhone = phone && phone.replace(/\D/g, "").length === 11;
      if (!isValidPhone) {
        toast.show({
          description: "Please enter a valid 10-digit phone number.",
          ...toastErrorConfig,
        });
        return;
      }
      verificationData.phone = phone;
      dataType = "phone";
    }

    try {
      setIsLoading(true);
      const response = await dispatch(checkUser(verificationData)).unwrap();

      if (!response.exists) {
        toast.show({
          description: "No user found",
          ...toastErrorConfig,
        });
        return;
      }

      if (response.deleted) {
        toast.show({
          description:
            "This profile has been deleted. Please contact support if you need assistance",
          ...toastErrorConfig,
        });
        return;
      }

      // console.log({ dataType, verificationData })

      // Send verification request to the backend
      dispatch(verifyCredential({ dataType, verificationData }))
        .unwrap()
        .then(() => {
          setIsLoading(false);
          // Navigate based on the type of verification
          if (dataType === "email" && email) {
            navigate(`/confirm/signin/email/${email}`);
          }
          if (dataType === "phone" && phone) {
            navigate(`/confirm/signin/phone/${phone}`);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.response && error.response.status === 500) {
            toast.show({
              description: `The ${dataType} provided is not valid. Please check and try again.`,
              ...toastErrorConfig,
            });
          } else {
            toast.show({
              description:
                "An unexpected error occurred. Please try again later.",
              ...toastErrorConfig,
            });
          }
        });
    } catch (error) {
      setIsLoading(false);
      toast.show({
        description: "Error checking user information. Please try again",
        ...toastErrorConfig,
      });
    }
  };

  return (
    <>
      <Box style={styles.wrapper}>
        <View style={isMobile() ? styles.containerMobile : styles.container}>
          <Center>
            <Text fontSize="2xl" bold>
              Sign In
            </Text>
            <Text mt="5px" mb="10px" fontSize="md">
              Welcome back, Sign In to your account
            </Text>
          </Center>
          <FormControl>
            {type === "/signin/email" && (
              <>
                <FormControl.Label>Email</FormControl.Label>
                <Input
                  autoFocus
                  value={email}
                  onChangeText={setEmail}
                  type="email"
                  textContentType="emailAddress"
                  keyboardType="email-address"
                  autoCapitalize="none"
                  autoCorrect={false}
                  autoCompleteType="email"
                  InputLeftElement={
                    <View style={styles.leftIcon}>
                      <Email />
                    </View>
                  }
                  placeholder="Email Address"
                />
              </>
            )}
            {type === "/signin/phone" && (
              <>
                <FormControl.Label>Phone Number</FormControl.Label>
                <View
                  style={[
                    {
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 12,
                      borderColor: isPhoneFocused ? "#1DCE80" : "#D9D9D9",
                      backgroundColor: isPhoneFocused ? "#E9F8EF" : "FAFAFA",
                      borderWidth: 1,
                      height: 46,
                      width: "100%",
                      paddingHorizontal: 12,
                      outline: isPhoneFocused ? "1px solid #1DCE80" : "none",
                    },
                  ]}
                >
                  <View style={{ marginRight: 10 }}>
                    <Phone />
                  </View>
                  <Text>+1 </Text>
                  <PhoneInput
                    value={phone}
                    onChange={setPhone}
                    type="tel"
                    maxLength="14"
                    placeholder="(222) 333-4455"
                    defaultCountry="US"
                    style={{
                      paddingLeft: 0,
                      outline: "none",
                      flex: 1,
                      borderWidth: 0,
                      backgroundColor: isPhoneFocused ? "#E9F8EF" : "#FAFAFA",
                      fontSize: 16,
                    }}
                    onFocus={() => setIsPhoneFocused(true)}
                    onBlur={() => setIsPhoneFocused(false)}
                  />
                </View>
              </>
            )}
            <View style={styles.divider} />
          </FormControl>
          <Button style={styles.button} onPress={confirmCode}>
            Continue
          </Button>
          <View style={styles.divider} />
          <View>
            <Text fontSize="sm">
              Don’t have an account?{" "}
              <Pressable style={styles.actionText} onPress={goToSignUp}>
                Sign Up
              </Pressable>
            </Text>
          </View>
          <View style={styles.divider} />
        </View>
      </Box>
      {isLoading && (
        <Modal
          isOpen={isLoading}
          size="full"
          backdropOpacity={0.9}
          useRNModal={true}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner size="lg" color="#0000ff" />
          </View>
        </Modal>
      )}
    </>
  );
}
