import * as React from "react";
import {
  Center,
  Checkbox,
  FormControl,
  Input,
  Button,
  Select,
  Spinner,
  Text,
  TextArea,
  View,
  useToast,
  ScrollView,
  Pressable,
  Popover,
  Modal,
  useBreakpointValue,
} from "native-base";
import styles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import { setInputValue } from "../../../networking/orders/ordersSlice";
import {
  Vin,
  Model,
  Year,
  Length,
  Height,
  Width,
  Weight,
  Upload,
} from "../../../assets/images/svg";
import { carsList, carsObj } from "../../../assets/data/carsList";
import { useEffect, useState, useCallback, useMemo } from "react";
import instance from "../../../networking/axios";
import { API_URL, imageHost } from "../../../config";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import BottomNavigator from "../../../components/BottomNavigator";
import ReactImageUploading from "react-images-uploading";
import { toastErrorConfig } from "../../../config/constants";
import Stepper from "../../../components/stepper";
import { getInfoByVinNumber } from "../../../networking/orders/ordersApi";
import { IoAlertCircleOutline } from "react-icons/io5";

export default function NOFFirstStepScreen() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const orderState = useSelector((state) => state.order);
  const toast = useToast();
  const [models, setModels] = useState([]);
  const [isSearchButtonHovered, setIsSearchButtonHovered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { orders, active } = useSelector((state) => state.profile);
  const profileOrder = orders.find((order) => order.id === id) || null;
  const defaultVehicle = {
    vin: "",
    brand: "",
    model: "",
    year: "",
    height: "",
    length: "",
    width: "",
    weight: "",
    isInoperable: false,
    isTrailerEnclosed: false,
    images: [],
    personalItems: "",
    havePersonal: false,
    hasCustom: false,
    customChanges: "",
    additionalEquipment: "",
  };
  const [localInputs, setLocalInputs] = useState({
    vehicles:
      profileOrder?.vehicles.length > 0
        ? profileOrder.vehicles
        : orderState?.vehicles.length > 0
        ? orderState.vehicles
        : [defaultVehicle],
    orderName: profileOrder?.orderName || orderState?.orderName || "",
  });

  const isMobile = useBreakpointValue({
    base: true, // represents mobile
    sm: false, // represents tablet or larger screens
  });

  useEffect(() => {
    if (!active) {
      navigate("/orders");
    }

    if (profileOrder) {
      // Set general order fields
      Object.entries(profileOrder).forEach(([key, value]) => {
        if (key !== "vehicles") {
          dispatch(setInputValue({ key, value }));
        }
      });

      if (profileOrder?.status === "pickedUp") {
        navigate(
          location.pathname.includes("request-edit")
            ? `/request-edit/3/${id}`
            : id
            ? `/edit/3/${id}`
            : "/create/3"
        );
        return;
      }
    }
  }, []);

  const handleChangeVehicleDetail = useCallback((index, changes) => {
    setLocalInputs((prevInputs) => {
      const updatedVehicles = [...prevInputs.vehicles];
      updatedVehicles[index] = { ...updatedVehicles[index], ...changes };
      return {
        ...prevInputs,
        vehicles: updatedVehicles,
      };
    });
  }, []);

  const createYearArray = () => {
    const currentYear = new Date().getFullYear() + 1; // make sure we can add cars from the next year
    const startYear = 1900;
    const yearArray = [];
    for (let year = startYear; year <= currentYear; year++) {
      yearArray.push(year.toString());
    }

    return yearArray.reverse();
  };

  const years = useMemo(() => createYearArray(), []);

  const validateVehicles = () => {
    for (let i = 0; i < localInputs.vehicles.length; i++) {
      const vehicle = localInputs.vehicles[i];

      if (!vehicle.vin || vehicle.vin.length !== 17) {
        toast.show({
          description: `Please fill in correct VIN for vehicle #${i + 1}`,
          ...toastErrorConfig,
        });
        return false;
      }
      if (!vehicle.brand) {
        toast.show({
          description: `Please fill in Make for vehicle #${i + 1}`,
          ...toastErrorConfig,
        });
        return false;
      }
      if (!vehicle.model) {
        toast.show({
          description: `Please fill in Model for vehicle #${i + 1}`,
          ...toastErrorConfig,
        });
        return false;
      }
      if (!vehicle.year) {
        toast.show({
          description: `Please fill in Year for vehicle #${i + 1}`,
          ...toastErrorConfig,
        });
        return false;
      }
      if (vehicle.images.length === 0) {
        toast.show({
          description: `Please upload at least one image for vehicle #${i + 1}`,
          ...toastErrorConfig,
        });
        return false;
      }
    }
    return true; // All checks passed
  };

  const checkForDuplicateVINs = () => {
    const vins = localInputs.vehicles.map((vehicle) => vehicle.vin);
    const uniqueVins = new Set(vins);
    return uniqueVins.size !== vins.length;
  };

  const nextStep = () => {
    if (checkForDuplicateVINs()) {
      toast.show({
        description:
          "Duplicate VIN detected. Each vehicle must have a unique VIN.",
        ...toastErrorConfig,
      });
      return; // Stop if duplicates are found
    }

    if (validateVehicles()) {
      let updatedOrderName = localInputs.orderName;
      if (localInputs.orderName === "") {
        updatedOrderName = localInputs.vehicles[0].vin.slice(-8);

        setLocalInputs((prevInputs) => ({
          ...prevInputs,
          orderName: updatedOrderName,
        }));
      }
      // Dispatch local state to Redux
      Object.entries({ ...localInputs, orderName: updatedOrderName }).forEach(
        ([key, value]) => {
          dispatch(setInputValue({ key, value }));
        }
      );

      navigate(
        location.pathname.includes("request-edit")
          ? `/request-edit/2/${id}`
          : id
          ? `/edit/2/${id}`
          : "/create/2"
      );
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  const cancel = () => {
    navigate("/orders");
  };

  const onChange = async (imageList, index) => {
    // Use Promise.all to upload images in parallel

    const uploadPromises = imageList
      .filter((image) => typeof image !== "string") // Ignore already-uploaded files
      .map((image) => uploadResource(image)); // Only upload new files
    try {
      const uploadedImages = await Promise.all(uploadPromises);

      const newImages = uploadedImages.filter((img) => img !== null);

      const updatedImages = [
        ...localInputs.vehicles[index].images,
        ...newImages,
      ];
      handleChangeVehicleDetail(index, { images: updatedImages });
    } catch (error) {
      console.error("Error uploading multiple images: ", error);
    }
  };

  const uploadResource = async ({ file }) => {
    try {
      const formattedFileName = file.name.replace(/\s+/g, "-"); // Format the file name

      const formData = new FormData();
      formData.append("file", file, formattedFileName);

      const result = await instance.post(
        `${API_URL}/api/v1/orders/upload`,
        formData
      );

      if (result?.data?.fileName) {
        // Return the full image URL for Redux state update
        return `${result.data.fileName}`;
      }
      return null;
    } catch (error) {
      console.error("Error uploading image: ", error);
      return null; // Return null to indicate failure
    }
  };

  const getByVin = async (vin, index) => {
    if (vin?.length < 17 || vin?.length > 17) {
      toast.show({
        description: "VIN is invalid",
        ...toastErrorConfig,
      });
      return;
    }
    setIsLoading(true);
    try {
      const res = await dispatch(getInfoByVinNumber({ vin })).unwrap();

      if (res) {
        handleChangeVehicleDetail(index, {
          brand: res.brand || "",
          model: res.model || "",
          year: res.year || "",
          height: res.height || "",
          width: res.width || "",
          weight: res.weight || "",
          length: res.length || "",
        });
      }
    } catch (error) {
      toast.show({
        description: "Unable to retrieve car info using the provided VIN",
        ...toastErrorConfig,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const validateSingleVehicle = (vehicle, index) => {
    if (!vehicle.vin || vehicle.vin.length !== 17) {
      toast.show({
        description: `Please fill in correct VIN for vehicle #${index + 1}`,
        ...toastErrorConfig,
      });
      return false;
    }
    if (!vehicle.brand) {
      toast.show({
        description: `Please fill in Make for vehicle #${index + 1}`,
        ...toastErrorConfig,
      });
      return false;
    }
    if (!vehicle.model) {
      toast.show({
        description: `Please fill in Model for vehicle #${index + 1}`,
        ...toastErrorConfig,
      });
      return false;
    }
    if (!vehicle.year) {
      toast.show({
        description: `Please fill in Year for vehicle #${index + 1}`,
        ...toastErrorConfig,
      });
      return false;
    }
    if (vehicle.images.length === 0) {
      toast.show({
        description: `Please upload at least one image for vehicle #${
          index + 1
        }`,
        ...toastErrorConfig,
      });
      return false;
    }
    // Add more checks as needed for other properties
    return true;
  };

  const addVehicle = useCallback(() => {
    const lastVehicle = localInputs.vehicles[localInputs.vehicles.length - 1];
    if (validateSingleVehicle(lastVehicle, localInputs.vehicles.length - 1)) {
      setLocalInputs((prevInputs) => ({
        ...prevInputs,
        vehicles: [...prevInputs.vehicles, { ...defaultVehicle }],
      }));
    }
  }, [localInputs, validateSingleVehicle, defaultVehicle]);

  const removeVehicle = useCallback(
    (index) => {
      setLocalInputs((prevInputs) => {
        if (index === 0 && prevInputs.vehicles.length === 1) {
          return { ...prevInputs, vehicles: [defaultVehicle] }; // Reset to default state
        } else {
          return {
            ...prevInputs,
            vehicles: prevInputs.vehicles.filter((_, i) => i !== index),
          };
        }
      });
    },
    [setLocalInputs, defaultVehicle]
  );

  return (
    <>
      <ScrollView keyboardShouldPersistTaps="handled" style={styles.container}>
        <Text style={styles.h1}>
          {location.pathname.includes("request-edit")
            ? "Request edit"
            : id
            ? "Edit order"
            : "Create order"}
        </Text>
        <View style={styles.stepper}>
          <Stepper currentStep={1} />
        </View>
        <View>
          <FormControl.Label>Order name/ID (optional)</FormControl.Label>
          <View
            style={[
              styles.inputGrid,
              { backgroundColor: "#F5F5F5", borderRadius: 12 },
            ]}
          >
            <Input
              key="orderName"
              isDisabled={location.pathname.includes("request-edit")}
              // value={localInputs.orderName}
              // onChangeText={(value) => {
              //   setLocalInputs((prevInputs) => ({
              //     ...prevInputs,
              //     orderName: value,
              //   }));
              // }}
              defaultValue={localInputs.orderName}
              onBlur={(e) => {
                const newValue = e.nativeEvent.text.trim();
                if (newValue !== localInputs.orderName) {
                  setLocalInputs((prevInputs) => ({
                    ...prevInputs,
                    orderName: e.nativeEvent.text,
                  }));
                }
              }}
              type="text"
              placeholder="Enter order name or ID"
            />
          </View>
          <View style={styles.divider} />
          {localInputs.vehicles.map((vehicle, index) => (
            <View key={index}>
              <View style={styles.vehicleForm}>
                <Text
                  style={{ fontSize: 18, fontWeight: "bold", marginBottom: 5 }}
                >
                  Vehicle # {index + 1}
                </Text>
                <View style={styles.inputsGrid}>
                  <View style={styles.inputGrid}>
                    <FormControl.Label>VIN*</FormControl.Label>
                    <View>
                      <Input
                        key={`vin-${index}`}
                        isDisabled={location.pathname.includes("request-edit")}
                        type="text"
                        defaultValue={vehicle.vin}
                        // value={vehicle.vin}
                        // onChangeText={(value) => {
                        //   const sanitizedValue = value
                        //     .replace(/[^A-Z0-9]/gi, "")
                        //     .toUpperCase();
                        //   handleChangeVehicleDetail(index, {
                        //     vin: sanitizedValue,
                        //   });
                        // }}
                        onKeyPress={(event) => {
                          if (event.nativeEvent.key === "Enter") {
                            const inputValue = event.target.value.trim()
                            getByVin(inputValue, index);
                          }
                        }}
                        onBlur={(e) => {
                          const newValue = e.nativeEvent.text.trim();
                          if (newValue !== vehicle.vin) {
                            handleChangeVehicleDetail(index, {
                              vin: e.nativeEvent.text,
                            });
                          }
                        }}
                        InputLeftElement={
                          <View style={styles.leftIcon}>
                            <Vin />
                          </View>
                        }
                        InputRightElement={
                          <View
                            onMouseEnter={() => setIsSearchButtonHovered(true)}
                            onMouseLeave={() => setIsSearchButtonHovered(false)}
                            style={[
                              styles.searchButton,
                              isSearchButtonHovered && styles.searchButtonHover,
                            ]}
                          >
                            <Pressable
                              isDisabled={location.pathname.includes(
                                "request-edit"
                              )}
                              onPress={() => getByVin(vehicle.vin, index)}
                            >
                              <Text style={styles.searchButtonText}>
                                Search
                              </Text>
                            </Pressable>
                          </View>
                        }
                        placeholder="VIN"
                      />
                    </View>
                  </View>
                  <View style={styles.inputGrid}>
                    <FormControl.Label>Make*</FormControl.Label>
                    <Select
                      key={`brand-${index}`}
                      isDisabled={location.pathname.includes("request-edit")}
                      InputLeftElement={
                        <View style={styles.leftIcon}>
                          <Model />
                        </View>
                      }
                      selectedValue={vehicle.brand}
                      onValueChange={(value) => {
                        setModels(carsObj[value]);
                        handleChangeVehicleDetail(index, { brand: value });
                      }}
                      minWidth="200"
                      accessibilityLabel="brand"
                      placeholder="Select"
                      mt="1"
                    >
                      {carsList.map((car) => (
                        <Select.Item key={car} label={car} value={car} />
                      ))}
                    </Select>
                  </View>
                  <View style={styles.inputGrid}>
                    <FormControl.Label>Model*</FormControl.Label>
                    <Select
                      key={`model-${index}`}
                      isDisabled={
                        !vehicle.brand ||
                        location.pathname.includes("request-edit")
                      }
                      onValueChange={(value) => {
                        handleChangeVehicleDetail(index, { model: value });
                      }}
                      selectedValue={vehicle.model}
                      InputLeftElement={
                        <View style={styles.leftIcon}>
                          <Model />
                        </View>
                      }
                      minWidth="200"
                      accessibilityLabel="models"
                      placeholder="Select"
                      mt="1"
                    >
                      {models.map((model) => (
                        <Select.Item key={model} label={model} value={model} />
                      ))}
                      {models.length === 0 && !!vehicle.model && (
                        <Select.Item
                          key={vehicle.model}
                          label={vehicle.model}
                          value={vehicle.model}
                        />
                      )}
                    </Select>
                  </View>
                  <View style={styles.inputGrid}>
                    <FormControl.Label>Year*</FormControl.Label>
                    <Select
                      key={`year-${index}`}
                      isDisabled={location.pathname.includes("request-edit")}
                      InputLeftElement={
                        <View style={styles.leftIcon}>
                          <Year />
                        </View>
                      }
                      selectedValue={vehicle.year.toString()}
                      onValueChange={(value) => {
                        handleChangeVehicleDetail(index, {
                          year: value.toString(),
                        });
                      }}
                      minWidth="200"
                      accessibilityLabel="year"
                      placeholder="Select"
                      mt="1"
                    >
                      {years.map((year) => (
                        <Select.Item key={year} label={year} value={year} />
                      ))}
                    </Select>
                  </View>
                  <View style={styles.inputGrid}>
                    <FormControl.Label>Weight</FormControl.Label>
                    <Input
                      key={`weight-${index}`}
                      isDisabled={location.pathname.includes("request-edit")}
                      // value={vehicle.weight}
                      // onChangeText={(value) => {
                      //   handleChangeVehicleDetail(index, { weight: value });
                      // }}
                      defaultValue={vehicle.weight}
                      onBlur={(e) => {
                        const newValue = e.nativeEvent.text.trim();
                        if (newValue !== vehicle.weight) {
                          handleChangeVehicleDetail(index, {
                            weight: newValue,
                          });
                        }
                      }}
                      type="text"
                      InputLeftElement={
                        <View style={styles.leftIcon}>
                          <Weight />
                        </View>
                      }
                      placeholder="Enter weight"
                    />
                  </View>
                  <View style={styles.inputGrid}>
                    <FormControl.Label>Length</FormControl.Label>
                    <Input
                      key={`length-${index}`}
                      isDisabled={location.pathname.includes("request-edit")}
                      // value={vehicle.length}
                      // onChangeText={(value) => {
                      //   handleChangeVehicleDetail(index, { length: value });
                      // }}
                      defaultValue={vehicle.length}
                      onBlur={(e) => {
                        const newValue = e.nativeEvent.text.trim();
                        if (newValue !== vehicle.length) {
                          handleChangeVehicleDetail(index, {
                            length: e.nativeEvent.text,
                          });
                        }
                      }}
                      type="text"
                      InputLeftElement={
                        <View style={styles.leftIcon}>
                          <Length />
                        </View>
                      }
                      placeholder="Enter length"
                    />
                  </View>
                  <View style={styles.inputGrid}>
                    <FormControl.Label>Height</FormControl.Label>
                    <Input
                      key={`height-${index}`}
                      isDisabled={location.pathname.includes("request-edit")}
                      // value={vehicle.height}
                      // onChangeText={(value) => {
                      //   handleChangeVehicleDetail(index, { height: value });
                      // }}
                      defaultValue={vehicle.height}
                      onBlur={(e) => {
                        const newValue = e.nativeEvent.text.trim();
                        if (newValue !== vehicle.height) {
                          handleChangeVehicleDetail(index, {
                            height: e.nativeEvent.text,
                          });
                        }
                      }}
                      type="text"
                      InputLeftElement={
                        <View style={styles.leftIcon}>
                          <Height />
                        </View>
                      }
                      placeholder="Enter height"
                    />
                  </View>
                  <View style={styles.inputGrid}>
                    <FormControl.Label>Width</FormControl.Label>
                    <Input
                      key={`width-${index}`}
                      isDisabled={location.pathname.includes("request-edit")}
                      // value={vehicle.width}
                      // onChangeText={(value) => {
                      //   handleChangeVehicleDetail(index, { width: value });
                      // }}
                      defaultValue={vehicle.width}
                      onBlur={(e) => {
                        const newValue = e.nativeEvent.text.trim();
                        if (newValue !== vehicle.width) {
                          handleChangeVehicleDetail(index, {
                            width: e.nativeEvent.text,
                          });
                        }
                      }}
                      type="text"
                      InputLeftElement={
                        <View style={styles.leftIcon}>
                          <Width />
                        </View>
                      }
                      placeholder="Enter width"
                    />
                  </View>
                </View>
                <View style={styles.divider} />
                <View style={styles.row}>
                  <Checkbox
                    key={`isInoperable-${index}`}
                    value="checkbox"
                    isChecked={vehicle.isInoperable}
                    onChange={(value) =>
                      handleChangeVehicleDetail(index, { isInoperable: value })
                    }
                    accessibilityLabel="checkbox"
                  />
                  <Popover
                    trigger={(triggerProps) => {
                      return (
                        <Pressable {...triggerProps}>
                          <View style={styles.row}>
                            <Text style={styles.textContainer}>
                              The vehicle is inoperable{" "}
                            </Text>
                            <IoAlertCircleOutline
                              size={18}
                              style={{ cursor: "pointer" }}
                            />
                          </View>
                        </Pressable>
                      );
                    }}
                  >
                    <Popover.Content maxWidth="350px">
                      <Popover.Arrow bg="gray.100" />
                      <Popover.CloseButton />
                      <Popover.Header bg="gray.100">
                        The vehicle is inoperable
                      </Popover.Header>
                      <Popover.Body bg="gray.100">
                        Confirm if the vehicle is inoperable and unable to start
                        or move under its own power.
                      </Popover.Body>
                    </Popover.Content>
                  </Popover>
                </View>

                <View style={styles.divider} />
                <View style={styles.row}>
                  <Checkbox
                    key={`isTrailerEnclosed-${index}`}
                    value="checkbox"
                    defaultIsChecked={vehicle.isTrailerEnclosed}
                    onChange={(value) =>
                      handleChangeVehicleDetail(index, {
                        isTrailerEnclosed: value,
                      })
                    }
                    accessibilityLabel="checkbox"
                  />
                  <Popover
                    trigger={(triggerProps) => {
                      return (
                        <Pressable {...triggerProps}>
                          <View style={styles.row}>
                            <Text style={styles.textContainer}>
                              The vehicle needs enslosed trailer{" "}
                            </Text>
                            <IoAlertCircleOutline
                              size={18}
                              style={{ cursor: "pointer" }}
                            />
                          </View>
                        </Pressable>
                      );
                    }}
                  >
                    <Popover.Content maxWidth="350px">
                      <Popover.Arrow bg="gray.100" />
                      <Popover.CloseButton />
                      <Popover.Header bg="gray.100">
                        Trailer Type
                      </Popover.Header>
                      <Popover.Body bg="gray.100">
                        If your vehicle requires additional security during
                        transportation, we recommend our enclosed trailer
                        option. This is ideal for luxury, classic, or exotic
                        cars as it provides a closed environment, shielding the
                        vehicle from weather and road debris. In contrast, an
                        open trailer is more economical and suitable for
                        standard vehicles, but does not offer the same level of
                        protection as an enclosed trailer. An enclosed trailer
                        typically costs $500-700 more than an open trailer.
                      </Popover.Body>
                    </Popover.Content>
                  </Popover>
                </View>
                {/* <View style={styles.divider} /> */}
                {/* <FormControl.Label>Additional equipment</FormControl.Label>
                <TextArea
                  value={vehicle.additionalEquipment}
                  onChangeText={(value) => {
                    handleChangeVehicleDetail(
                      index,
                      "additionalEquipment",
                      value
                    );
                  }}
                  numberOfLines={2}
                  placeholder="Enter additional equipment"
                /> */}
                <View style={styles.divider} />
                <>
                  <View style={styles.row}>
                    <Center>
                      <Checkbox
                        key={`hasCustom-${index}`}
                        value="checkbox"
                        isChecked={vehicle.hasCustom}
                        onChange={(value) => {
                          handleChangeVehicleDetail(index, {
                            hasCustom: value,
                          });
                          if (!value) {
                            handleChangeVehicleDetail(index, {
                              customChanges: "",
                            });
                          }
                        }}
                        accessibilityLabel="checkbox"
                      />
                    </Center>

                    <Text style={styles.textContainer}>
                      The vehicle is custom/modified
                    </Text>
                  </View>
                  {vehicle.hasCustom && (
                    <>
                      <View style={styles.divider} />
                      <TextArea
                        key={`customChanges-${index}`}
                        // value={vehicle.customChanges}
                        // onChangeText={(value) => {
                        //   handleChangeVehicleDetail(index, {
                        //     customChanges: value,
                        //   });
                        // }}
                        defaultValue={vehicle.customChanges}
                        onBlur={(e) => {
                          const newValue = e.nativeEvent.text.trim();
                          if (newValue !== vehicle.customChanges) {
                            handleChangeVehicleDetail(index, {
                              customChanges: e.nativeEvent.text,
                            });
                          }
                        }}
                        numberOfLines={2}
                        placeholder="Is the vehicle lifted? Does it have racks? Please describe any aftermarket modifications."
                      />
                    </>
                  )}
                </>
                <View style={styles.divider} />
                <>
                  <View style={styles.row}>
                    <Center>
                      <Checkbox
                        key={`havePersonal-${index}`}
                        value="checkbox"
                        isChecked={vehicle.havePersonal}
                        onChange={(value) => {
                          handleChangeVehicleDetail(index, {
                            havePersonal: value,
                          });
                          if (!value) {
                            handleChangeVehicleDetail(index, {
                              personalItems: "",
                            });
                          }
                        }}
                        accessibilityLabel="checkbox"
                      />
                    </Center>

                    <Text style={styles.textContainer}>
                      I will have personal items in my vehicle
                    </Text>
                  </View>
                  {vehicle.havePersonal && (
                    <>
                      <View style={styles.divider} />
                      <TextArea
                        key={`personalItems-${index}`}
                        // value={vehicle.personalItems}
                        // onChangeText={(value) => {
                        //   handleChangeVehicleDetail(index, {
                        //     personalItems: value,
                        //   });
                        // }}
                        defaultValue={vehicle.personalItems}
                        onBlur={(e) => {
                          const newValue = e.nativeEvent.text.trim();
                          if (newValue !== vehicle.personalItems) {
                            handleChangeVehicleDetail(index, {
                              personalItems: e.nativeEvent.text,
                            });
                          }
                        }}
                        numberOfLines={2}
                        placeholder="Please describe the items and their approximate weight"
                      />
                    </>
                  )}
                </>
                <View style={styles.divider} />
                <ReactImageUploading
                  multiple
                  value={vehicle.images}
                  onChange={(imageList) => onChange(imageList, index)}
                  maxNumber={10}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemoveAll,
                    onImageUpdate,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    <View className="upload__image-wrapper">
                      <View style={{ marginBottom: 10 }}>
                        <ScrollView
                          horizontal
                          showsHorizontalScrollIndicator={false}
                          style={{ flexDirection: "row" }}
                        >
                          {imageList.map((image, imgIndex) => {
                            const imageUrl = `${image.fileName || image}`;
                            return (
                              <View
                                key={imgIndex}
                                style={{ marginTop: 10, marginRight: 10 }}
                              >
                                <img
                                  src={`${imageHost}${imageUrl}`}
                                  alt={`uploaded-${imgIndex}`}
                                  // width="100"
                                  // height="100"
                                  style={{
                                    width: isMobile ? "100px" : "200px",
                                    height: isMobile ? "100px" : "200px",
                                    objectFit: "cover",
                                    borderRadius: 12,
                                    filter: "blur(0.5px)", // Helps with display on smaller resolutions
                                  }}
                                />
                                <Pressable
                                  onPress={() => {
                                    const newImageList = vehicle.images.filter(
                                      (_, i) => i !== imgIndex
                                    ); // Filter out the removed image
                                    handleChangeVehicleDetail(index, {
                                      images: newImageList,
                                    });
                                  }}
                                  style={{
                                    position: "absolute",
                                    top: -5,
                                    right: -5,
                                    backgroundColor: "white",
                                    width: 30,
                                    height: 30,
                                    borderRadius: 12,
                                    alignItems: "center",
                                    justifyContent: "center",
                                    shadowColor: "#000",
                                    shadowOffset: { width: 0, height: 2 },
                                    shadowOpacity: 0.25,
                                    shadowRadius: 3.84,
                                    elevation: 5,
                                  }}
                                >
                                  <Text
                                    style={{
                                      fontSize: 16,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    ×
                                  </Text>
                                </Pressable>
                              </View>
                            );
                          })}
                        </ScrollView>
                      </View>
                      <View
                        style={styles.dragImages}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        <Upload />
                        <Text style={styles.uploadText}>
                          Drag & drop files or Browse
                        </Text>
                        <Text style={styles.supportedText}>
                          Supported formats: JPEG, PNG
                        </Text>
                      </View>
                    </View>
                  )}
                </ReactImageUploading>

                <View style={styles.divider} />
                <View style={{ width: 127 }}>
                  <Button
                    isDisabled={
                      location.pathname.includes("request-edit") || !active
                    }
                    onPress={() => removeVehicle(index)}
                    variant="outline"
                  >
                    Remove
                  </Button>
                </View>
              </View>
              <View style={styles.divider} />
            </View>
            // <VehileForm key={index} vehicle={vehicle} index={index} />
          ))}
          {localInputs.vehicles.length < 10 && (
            <View style={{ width: 127 }}>
              <Button
                isDisabled={
                  location.pathname.includes("request-edit") || !active
                }
                onPress={addVehicle}
              >
                Add Vehicle
              </Button>
            </View>
          )}
          <View style={styles.divider} />
          <View style={styles.emptySpace} />
          <BottomNavigator
            nextOnPress={nextStep}
            nextText={"Next"}
            prevOnPress={cancel}
            prevText={"Cancel"}
          />
        </View>
      </ScrollView>
      <Modal
        isOpen={isLoading}
        size="full"
        backdropOpacity={0.9}
        useRNModal={true}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size="lg" color="#0000ff" />
          {/* <Text style={{ color: "#ffffff", marginTop: 10, fontSize: 20 }}>
            Loading...
          </Text> */}
        </View>
      </Modal>
    </>
  );
}
