import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  status: {
    borderRadius: 8,
    paddingLeft: 8,
    paddingRight: 4,
    color: "white",
    fontSize: 16,
  },
  statusRow: {
    flexDirection: 'row',
  },
});

export default styles;
