import * as React from "react";
import styles from "./styles";
import { Button, View } from "native-base";

export default function BottomNavigator({
  prevText,
  nextText,
  prevOnPress,
  nextOnPress,
  showSaveButton = false,
  saveOnPress,
}) {
  return (
    <View style={styles.wrapper}>
      <View style={styles.button}>
        <Button variant="outline" colorScheme="secondary" onPress={prevOnPress}>
          {prevText}
        </Button>
      </View>
      <View style={styles.button}>
        <Button onPress={nextOnPress}>{nextText}</Button>
      </View>
      {showSaveButton && (
        <View style={styles.button}>
          <Button variant="outline" onPress={saveOnPress}>
            Save for Future
          </Button>
        </View>
      )}
    </View>
  );
}
