import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingHorizontal: 20,
    paddingBottom: 20,
  },
  headerBlock: {
    color: "#171717",
    fontSize: 18,
    fontWeight: "700",
    marginVertical: 10,
  },
  locationWrapper: {
    flexDirection: "row",
    marginTop: 12,
  },
  locationTextWrapper: {
    marginLeft: 6,
    flex: 1,
  },
  locationText: {
    color: "#737373",
    fontWeight: "500",
  },
  dateRow: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 8,
    flexWrap: "wrap",
    paddingRight: 10,
  },
  calendarIcon: {
    marginRight: 8,
  },
  button: {
    marginTop: 24,
    marginBottom: 80,
  },
  row: {
    flexDirection: "row",
  },
  recipientRow: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 7,
  },
  ml4: {
    marginLeft: 4,
  },
  emptySpace: {
    height: 80,
  },
  buttonsRow: {
    flexDirection: "row",
    flex: 2,
  },
  cancel: {
    flex: 1,
    borderWidth: 1,
    borderColor: "#000",
    marginRight: 3,
  },
  publishButton: {
    flex: 1,
    marginLeft: 3,
  },
  addressIcon: {
    marginRight: 6,
    marginLeft: 2
  },
  locationPinWrapper: {
    marginVertical: 10,
  },
  locationDetailsWrapper: {
    flexDirection: "column",
    alignItems: "flex-start",
    flex: 1,
  },
  h1: {
    fontSize: '24px',
    fontWeight: '700',
    lineHeight: '30px',
    marginBottom: '20px',
  },
  vehicleWrapper: {
    marginBottom: 20,
    padding: 10,
    borderRadius: 8,
    backgroundColor: "#f9f9f9",
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
  },
  toggleButton: {
    padding: 10,
    borderRadius: 5,
    cursor: "pointer",
  },
  vehicleDetails: {
    overflow: "hidden",
    transition: "max-height 0.3s ease-out",
    maxHeight: "0px", // Default collapsed state
  },
});

export default styles;
