import * as React from "react";
import { Logo } from "../../assets/images/svg";
import styles from "./styles";
import { Button, View, Center, Pressable, Text } from "native-base";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserProfileButton from "./userProfileButton";

export default function Header() {
  const navigate = useNavigate();
  const { userToken } = useSelector((state) => state.auth);
  const { fullName } = useSelector((state) => state.profile);

  return (
    <View>
      <View style={styles.header}>
        <Pressable
          onPress={() => {
            userToken ? navigate("/orders") : navigate("/");
          }}
        >
          <Logo />
        </Pressable>
        {!!userToken && <UserProfileButton name={fullName} />}
      </View>
      <View style={{ marginTop: 90 }}>
        <Outlet />
      </View>
    </View>
  );
}
