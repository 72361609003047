const styles = {
  container: {
    paddingHorizontal: 20,
    flex: 1,
    height: "100vh",
  },
  myOrdersTitle: {
    fontSize: 24,
    fontWeight: "700",
    lineHeight: 30,
  },
  orderContainer: {
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: "#E5E5E5",
    borderRadius: 12,
    padding: 20,
    flexDirection: "column",
    //width: 374,
    height: 210,
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
    margin: 6,
  },
  orderTextContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  orderName: {
    flex: 1,
    fontSize: 18,
    fontWeight: "600",
    wordBreak: "break-word",
    overflow: "hidden", // Hides overflow content
    whiteSpace: "nowrap", // Keeps text on one line
    textOverflow: "ellipsis", // Adds ellipsis to truncated text
  },
  orderText: {
    flex: 1,
    fontSize: 14,
    fontWeight: "400",
    wordBreak: "break-word",
    overflow: "hidden", // Hides overflow content
    whiteSpace: "nowrap", // Keeps text on one line
    textOverflow: "ellipsis", // Adds ellipsis to truncated text
  },
  icon: {
    marginRight: 4,
    flexShrink: 0,
  },
  line: {
    marginHorizontal: 10,
  },
  createNewOrder: {
    width: 129,
  },
  footerBlock: {
    height: 100,
  },
  subHeaderWrapper: {
    flexDirection: "row",
    marginBottom: 15,
    // marginTop: 20,
    justifyContent: "space-between",
    paddingHorizontal: 10,
  },
  bidText: {},
  status: {
    width: 8,
    height: 8,
    borderRadius: 4,
    marginRight: 4,
  },
  statusRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  button: {
    width: 127,
    height: 34,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 12,
  },
  publishButton: {
    flex: 1,
    marginLeft: 3,
  },
  buttonRow: {
    flex: 1,
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    marginBottom: 2,
  },
  disputeButton: {
    width: 107,
    height: 34,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 12,
    marginTop: 8,
  },
  buttonsContainer: {
    flexDirection: "row",
    gap: 20,
  },
  buttonsRow: {
    flexDirection: "row",
  },
  cancel: {
    borderWidth: 1,
    borderColor: "#000",
    marginRight: 3,
    width: 116,
  },
  cancelButton: {
    flex: 1,
    borderWidth: 1,
    borderColor: "#000",
    marginRight: 3,
  },
  acButton: {
    marginLeft: 3,
    width: 116,
  },
  addPhotoContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 12,
  },
  addPhotoText: {
    fontSize: 12,
    color: "#171717",
    marginLeft: 12,
  },
  image: {
    borderRadius: 8,
  },
  emptyText: {
    fontSize: 16,
    textAlign: "center",
    margin: 32,
  },
  emptyContainer: {
    alignItems: "center",
    paddingTop: 40,
  },
  wrapper: {
    flex: 1,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    // marginTop: (window.innerHeight - 600) / 2 - headerHeight,
  },
  auctionStatus: {
    marginBottom: 4,
  },
  inactiveContainer: {
    opacity: 0.5, // Semi-transparent to show it's inactive
    backgroundColor: "#F3F4F6", // Muted background color
  },
  sendingContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 8,
  },
  sendingText: {
    fontSize: 14,
    marginLeft: 8,
    color: "#4A5568",
  },
  sendOrderButton: {
    backgroundColor: "#FFA500",
  },
  draftText: {
    fontSize: 12,
    color: "gray",
    marginTop: 5,
  },
  thumbButton: {
    width: 50,
    height: 50,
    borderRadius: 25,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F3F4F6",
    borderWidth: 1,
    borderColor: "#E5E7EB",
    cursor: "pointer",
  },
  selectedButtonUp: {
    backgroundColor: "#1DCE80",
    borderColor: "#1DCE80",
  },
  selectedButtonDown: {
    backgroundColor: "#B91C1C",
    borderColor: "#B91C1C",
  },
  spacer: {
    width: 16,
  },
  buttonsWrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginVertical: 14,
  },
  dropdownContainer: {
    position: "absolute",
    width: "100%",
    backgroundColor: "white",
    zIndex: 1000,
    marginRight: 10,
    boxShadow: "0 2px 6px rgba(0, 0, 0, 0.3)",
  },
  dropdownItem: {
    flex: 1,
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#ccc",
  },
  truckIconContainer: {
    flexShrink: 0, // Ensure the Truck2 icon does not shrink or disappear
  },
  leftIcon: {
    marginLeft: 12,
  },
};

export default styles;
