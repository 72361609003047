import * as React from "react";

import { extendTheme, NativeBaseProvider, Pressable } from "native-base";
import { Provider } from "react-redux";
import store, { persistor } from "./networking/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { PersistGate } from "redux-persist/integration/react";
import { Router } from "./navigator";
export default function App() {
  const newTheme = {
    components: {
      Button: {
        baseStyle: {
          borderRadius: "12px",
          width: "full",
          fontSize: 300,
          borderColor: "#1DCE80",
        },
        defaultProps: {
          _text: {
            fontWeight: "600",
            fontSize: "14px",
          },
        },
        variants: {
          solid: {
            bg: "primary.600",
            _hover: {
              bg: "#17b36d",
            },
            _text: {
              color: "#FFFFFF",
            },
          },
          fatal: {
            bg: "#B91C1C",
            color: "#FFFFFF",
            _hover: {
              bg: "#991A1A",
            },
            _text: {
              color: "#FFFFFF",
            },
          },
          warning: {
            bg: "#FFA500",
            _hover: {
              bg: "#E59400",
            },
            _text: {
              color: "#FFFFFF",
            },
          },
          cancel: {
            bg: "#FFFFFF",
            color: "#A0A0A0",
            borderColor: "#000",
            borderWidth: "1px",
            _hover: {
              bg: "#F0F0F0",
            },
            _text: {
              color: "#A0A0A0",
            },
          },
        },
      },
      Pressable: {
        baseStyle: {
          _hover: {
            color: "#17b36d",
          },
          fontWeight: "700",
          fontSize: 16,
        },
        defaultProps: {
          _text: {
            fontWeight: "600",
            fontSize: "14px",
          },
        },
        variants: {
          option: {
            color: "black",
            borderRadius: 4,
            _hover: {
              bg: "#1DCE80",
              color: "white",
            },
            fontWeight: "500",
            fontSize: 14,
          },
        },
      },
      Input: {
        baseStyle: {
          height: 44,
          borderRadius: "12px",
        },
        defaultProps: {
          fontSize: "16px",
        },
      },
      Text: {
        defaultProps: {
          fontSize: "16px",
        },
      },
      TextArea: {
        defaultProps: {
          fontSize: "16px",
        },
      },
    },
    fontSizes: {
      "2xs": 10,
      xs: 12,
      sm: 14,
      md: 16,
      lg: 18,
      xl: 20,
      "2xl": 24,
      "3xl": 30,
      "4xl": 36,
      "5xl": 48,
      "6xl": 60,
      "7xl": 72,
      "8xl": 96,
      "9xl": 128,
    },
    fontWeights: {
      hairline: 100,
      thin: 200,
      light: 300,
      normal: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      extrabold: 800,
      black: 900,
      extraBlack: 950,
    },
    colors: {
      primary: {
        800: "#a7f3d0",
        600: "#1DCE80",
      },
      secondary: {
        600: "#000000",
      },
    },
    breakpoints: {
      base: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1280,
    },
  };
  const theme = extendTheme({ ...newTheme });
  return (
    <Provider store={store}>
      <GoogleOAuthProvider clientId="521885662516-a520u4v7u4ur8n8s33l0880s2bbar4le.apps.googleusercontent.com">
        <PersistGate loading={null} persistor={persistor}>
          <NativeBaseProvider theme={theme}>
            <Router />
          </NativeBaseProvider>
        </PersistGate>
      </GoogleOAuthProvider>
    </Provider>
  );
}
