const styles = {
  container: {
    paddingHorizontal: 20,
    paddingBottom: 20,
    //  paddingTop: 20,
    height: "90vh",
    flex: 1,
  },
  pageName: {
    marginBottom: 20,
    // marginTop: 5,
    fontSize: 20,
    fontWeight: "600",
    textAlign: "center",
  },
  bidWrapper: {
    //borderColor: "#E5E5E5",
    // borderWidth: 1,
    //  padding: 2,
    backgroundColor: "#F3F4F6",
    borderColor: "#1DCE80",
    borderWidth: 2,
    borderRadius: 12,
    padding: 15,
    marginBottom: 16,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
  },
  bidName: {
    fontSize: 18,
    fontWeight: "700",
    color: "#111827",
  },
  bidPrice: {
    marginTop: 12,
    fontSize: 16,
    fontWeight: "600",
  },
  buttonsWrapper: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
    marginBottom: 8,
  },
  buttonText: {
    color: "#fff",
    fontSize: 12,
    fontWeight: "600",
  },
  button: {
    flex: 1,
  },
  divider: {
    width: 6,
  },
  counterButton: {
    borderColor: "#000",
    borderWidth: 1,
  },

  buttonsRow: {
    flexDirection: "row",
    flex: 2,
  },
  cancel: {
    flex: 1,
    marginRight: 3,
  },
  acButton: {
    flex: 1,
    marginLeft: 3,
  },
  appleButton: {
    flex: 1,
    marginLeft: 3,
    backgroundColor: "#000000",
  },
  dcButton: {
    flex: 1,
    marginLeft: 3,
  },
  // ratingWrapper: {
  //   flexDirection: "row",
  //   justifyContent: "space-between",
  // },
  starStyle: {
    marginHorizontal: 0,
  },
  leftIcon: {
    marginLeft: 12,
  },
  newBidText: {
    color: "#1DCE80",
    marginBottom: 8,
    fontSize: 18,
    fontWeight: "bold",
  },
  inactiveBidWrapper: {
    borderColor: "#E5E7EB",
    borderWidth: 1,
    borderRadius: 8,
    padding: 8,
    marginBottom: 8,
    backgroundColor: "#F8FAFC",
  },
  previousBidsWrapper: {
    padding: 8,
    backgroundColor: "#F1F5F9",
    borderRadius: 8,
  },
  foldButton: {
    marginVertical: 12,
    alignItems: "center",
  },
  foldButtonText: {
    color: "#1D4ED8",
    fontWeight: "600",
    fontSize: 14,
  },
  headerWrapper: {
    width: "70%",
  },
  carrierSince: {
    fontSize: 14,
    color: "#6B7280",
  },
  ratingWrapper: {
    alignItems: "flex-end",
  },
  goodReviews: {
    marginRight: 10,
    color: "#1DCE80",
  },
  badReviews: {
    color: "#B91C1C",
  },
  detailsWrapper: {
    marginTop: 8,
  },
  bidText: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#333",
  },
  bidAmount: {
    fontSize: 18,
    fontWeight: "bold",
    color: "#1DCE80",
  },
  reviewsButton: {
    marginLeft: 5,
    textDecorationLine: "underline",
    color: "blue",
    fontWeight: "500",
    fontSize: 14,
  },
  reviewItem: {
    padding: "15px",
    marginBottom: "10px",
    borderRadius: "8px",
    backgroundColor: "#f9f9f9",
    boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
    border: "1px solid #ddd",
  },
  reviewText: {
    fontSize: 14,
    fontStyle: "italic",
  },
  reviewMeta: {
    fontSize: 12,
    color: "#666",
  },
  closeButton: {
    marginTop: 10,
    backgroundColor: "#1DCE80",
  },
  emptyText: {
    fontSize: 16,
    textAlign: "center",
    margin: 32,
  },
  emptyContainer: {
    alignItems: "center",
  },
  wrapper: {
    flex: 1,
    marginTop: 40,
    alignItems: "center",
  },
};

export default styles;
