import * as React from "react";
import styles from "./styles";
import { Text, View, Popover, Pressable } from "native-base";
import { IoAlertCircleOutline } from "react-icons/io5";

export const textMapper = {
  notSent: "New",
  new: "Draft",
  auction: "Bid auction",
  waitingPickUp: "Waiting for pickup",
  waitingPickUpEditPending: "Edit requested",
  waitingPickUpReviewChanges: "Please review changes",
  pickedUp: "Picked Up",
  pickedUpEditPending: "Edit requested",
  pickedUpReviewChanges: "Please review changes",
  delivered: "Delivered",
  completed: "Completed",
  completedAndRated: "Feedback received",
  dispute: "In dispute",
  carrierProblem: "Investigating",
};

export default function BidStatus({ status }) {
  const colorMapper = {
    notSent: "#C0C0C0",
    new: "#C0C0C0",
    auction: "#C0C0C0",
    waitingPickUp: "#007AFF",
    pickedUp: "#FF9500",
    delivered: "#34C759",
    completedAndRated: "#34C759",
    dispute: "#FF3B30",
    carrierProblem: "#FF3B30",
  };

  const statusExplanations = {
    notSent:
      "New order: The order has been created but not yet sent to the board.",
    new: "Draft order: The order is in draft status and may still be edited before sending to the board.",
    auction:
      "Bid auction: The order is currently in an auction phase and awaiting bids from carriers.",
    waitingPickUp:
      "Waiting for pickup: The order has been assigned to a carrier and is awaiting pickup.",
    waitingPickUpEditPending:
      "Edit requested: Changes to the order have been requested and are pending confirmation from carrier.",
    waitingPickUpReviewChanges:
      "Please review changes: Changes to the order have been made by carrier. Please review them to proceed.",
    pickedUp:
      "Picked Up: The order has been picked up by the assigned carrier.",
    pickedUpEditPending:
      "Edit requested: Changes to the order have been requested and are pending confirmation from carrier.",
    pickedUpReviewChanges:
      "Please review changes: Changes to the order have been made by carrier. Please review them to proceed.",
    delivered:
      "Delivered: The order has been successfully delivered to its destination.",
    completedAndRated:
      "Feedback received: The order has been completed and rated. Thank you for using our services.",
    dispute:
      "In dispute: There is an issue with the order, and it is currently under review or dispute resolution.",
    carrierProblem:
      "Investigating: A problem with the carrier has been reported and is under investigation.",
  };

  return (
    <Popover
      trigger={(triggerProps) => {
        return (
          <Pressable {...triggerProps}>
            <View style={styles.statusRow}>
              <Text
                style={[
                  styles.status,
                  { backgroundColor: colorMapper[status] },
                ]}
              >
                {textMapper[status] || status}{" "}
              </Text>
              <IoAlertCircleOutline
                size={18}
                style={{ cursor: "pointer", marginLeft: 4, paddingTop: 3, }}
              />
            </View>
          </Pressable>
        );
      }}
    >
      <Popover.Content maxWidth="350px">
        <Popover.Arrow bg="gray.100" />
        <Popover.CloseButton />
        <Popover.Header bg="gray.100">Status Explanation</Popover.Header>
        <Popover.Body bg="gray.100">{statusExplanations[status]}</Popover.Body>
      </Popover.Content>
    </Popover>
  );
}
