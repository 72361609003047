import { createSlice } from "@reduxjs/toolkit";
import { getPlaces } from "./ordersApi";

const initialState = {
  orderName: "",
  flexiblePickupDate: false,
  flexibleDeliveryDate: false,
  senderContactName: "",
  senderContactPhone: "",
  senderContactEmail: "",
  recipientContactName: "",
  recipientContactEmail: "",
  recipientContactPhone: "",
  pickUpLocation: "",
  deliveryLocation: "",
  pickUpDate1: "",
  deliveryDate1: "",
  bidAmount: "",
  carrierEmail: "",
  carrierName: "",
  carrierPhone: "",
  carrierSince: "",
  numberOfTrucks: "",
  pickUpPINRequired: true,
  deliveryPINRequired: true,
  places: [],
  vehicles: [],
};

export const orderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    setInputValue: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
    addVehicle: (state, action) => {
      state.vehicles.push(action.payload);
    },
    updateVehicleDetail: (state, action) => {
      const { index, key, value } = action.payload;
      if (state.vehicles[index]) {
        state.vehicles[index][key] = value;
      }
    },
    removeVehicle: (state, action) => {
      state.vehicles.splice(action.payload, 1);
    },
    clearInputValues() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPlaces.fulfilled, (state, action) => {
      state.places = action.payload;
    });
  },
});

export const {
  setInputValue,
  addVehicle,
  updateVehicleDetail,
  removeVehicle,
  clearInputValues,
} = orderSlice.actions;
export const selectInputValues = (state) => state.order;
export default orderSlice.reducer;
