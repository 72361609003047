import * as React from "react";
import {
  Button,
  FormControl,
  View,
  Text,
  useToast,
  Checkbox,
  AlertDialog,
  Pressable,
  Center,
  Input,
  ScrollView,
  Popover,
  SearchIcon,
  Divider,
} from "native-base";
import styles from "./styles";
import { Geo } from "../../../assets/images/svg";
// import { GooglePlacesAutocomplete } from "react-native-google-places-autocomplete";
import { useCallback, useEffect, useRef, useState } from "react";
// import MapView, {Marker} from 'react-native-maps';
// import MapViewDirections from 'react-native-maps-directions';
import { setInputValue } from "../../../networking/orders/ordersSlice";
import { useDispatch, useSelector } from "react-redux";
import BottomNavigator from "../../../components/BottomNavigator";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Autocomplete from "react-google-autocomplete";
import { GOOGLE_API_KEY } from "../../../config";
import {
  APIProvider,
  Map,
  Marker,
  useMap,
  useMapsLibrary,
} from "@vis.gl/react-google-maps";
import {
  toastErrorConfig,
  toastSuccessConfig,
  toastWarningConfig,
} from "../../../config/constants";
import Stepper from "../../../components/stepper";
import { Email, Phone, User } from "../../../assets/images/svg";
import PhoneInput from "react-phone-number-input/input";
import { createPlace } from "../../../networking/orders/ordersApi";
import { Dimensions } from "react-native";
import { IoAlertCircleOutline } from "react-icons/io5";
import { formatPhone, parseLocation } from "../../../utils";
import { deletePlace } from "../../../networking/profile/profileApi";
import { removePlaceById } from "../../../networking/profile/profileSlice";

export default function NOFSecondStepScreen() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const toast = useToast();
  const { orders, active, places } = useSelector((state) => state.profile);
  const profileOrder = orders.find((order) => order.id === id) || null;
  const [pickUpLocationCoordinates, setPickUpLocationCoordinates] = useState(
    {}
  );
  const { fullName, email, phone } = useSelector((state) => state.profile);
  const [isSenderPhoneFocused, setIsSenderPhoneFocused] = useState(false);
  const position = { lat: 40.6973709, lng: -74.1444872 };
  const orderState = useSelector((state) => state.order);
  const { pickUpLocation, pickUpPINRequired } = orderState;
  const [shownCancelPINModal, setShownCancelPINModal] = useState(false);
  const [modalLocation, setModalLocation] = useState(""); // Track whether it's for pickup or delivery
  const [localInputs, setLocalInputs] = useState({
    pickUpLocation: orderState.pickUpLocation || "",
    senderContactName: orderState.senderContactName || "",
    senderContactPhone: orderState.senderContactPhone || "",
    senderContactEmail: orderState.senderContactEmail || "",
    checkboxSender: orderState.checkboxSender || false,
    placeName: orderState.placeName || "",
    pickUpDate1: orderState.pickUpDate1 || null,
    deliveryDate1: orderState.deliveryDate1 || null,
    flexiblePickupDate: orderState.flexiblePickupDate || false,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filteredPlaces, setFilteredPlaces] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [wasPickupChecked, setWasPickupChecked] = useState(pickUpPINRequired);
  const [isEdited, setIsEdited] = useState(false);
  const screenHeight = Dimensions.get("window").height;
  const [isAddressFocused, setIsAddressFocused] = useState(false);
  const today = new Date().toISOString().split("T")[0];
  const pickupPlaces = places.filter((place) => place.type === "pickup");

  const handleOpenSavePlaceModal = () => {
    if (!localInputs.pickUpLocation) {
      toast.show({
        description: "Please fill in  pickup location",
        ...toastErrorConfig,
      });
      return;
    }

    if (
      !localInputs.senderContactName ||
      !localInputs.senderContactPhone ||
      !localInputs.senderContactEmail
    ) {
      toast.show({
        description: "Please fill in sender information to proceed",
        ...toastErrorConfig,
      });
      return;
    }

    if (
      !localInputs.senderContactEmail.includes("@") ||
      !localInputs.senderContactEmail.includes(".")
    ) {
      toast.show({
        description: "Sender email is invalid",
        ...toastErrorConfig,
      });
      return;
    }

    const isValidSenderPhone =
      localInputs.senderContactPhone &&
      localInputs.senderContactPhone.replace(/\D/g, "").length === 11;

    if (!isValidSenderPhone) {
      toast.show({
        description: "Please enter a valid 10-digit sender's phone number",
        ...toastErrorConfig,
      });
      return;
    }
    setIsModalVisible(true);
  };

  const handleCloseSavePlaceModal = () => {
    setIsModalVisible(false);
  };

  const handleConfirmSavePlaceModal = async () => {
    const placeData = {
      placeName: localInputs.placeName,
      fullName: localInputs.senderContactName,
      phone: localInputs.senderContactPhone,
      email: localInputs.senderContactEmail,
      address: localInputs.pickUpLocation,
      type: "pickup",
    };
    try {
      await dispatch(createPlace(placeData)).unwrap();
      toast.show({
        description: "Place saved successfully!",
        ...toastSuccessConfig,
      });
      setIsEdited(false);
      handleCloseSavePlaceModal();
    } catch (error) {
      console.error("Failed to save place:", error);
      toast.show({
        description: "Failed to save place. Please try again.",
        ...toastErrorConfig,
      });
    }
  };

  const handleChangePlaceName = (value) => {
    setLocalInputs((prev) => ({
      ...prev, // Spread the previous state here as well
      placeName: value,
    }));
  };

  const nextStep = () => {
    if (!localInputs.pickUpLocation) {
      toast.show({
        description: "Please fill in  pickup location",
        ...toastErrorConfig,
      });
      return;
    }

    const parsedPickUpLocation = parseLocation(localInputs.pickUpLocation);

    if (
      !parsedPickUpLocation.street ||
      !parsedPickUpLocation.city ||
      !parsedPickUpLocation.state ||
      !parsedPickUpLocation.zipCode
    ) {
      toast.show({
        description: "Please fill in  full address",
        ...toastErrorConfig,
      });
      return;
    }

    if (
      !localInputs.senderContactName ||
      !localInputs.senderContactPhone ||
      !localInputs.senderContactEmail
    ) {
      toast.show({
        description: "Please fill in sender information to proceed",
        ...toastErrorConfig,
      });
      return;
    }

    if (
      !localInputs.senderContactEmail.includes("@") ||
      !localInputs.senderContactEmail.includes(".")
    ) {
      toast.show({
        description: "Sender email is invalid",
        ...toastErrorConfig,
      });
      return;
    }

    const isValidSenderPhone =
      localInputs.senderContactPhone &&
      localInputs.senderContactPhone.replace(/\D/g, "").length === 11;

    if (!isValidSenderPhone) {
      toast.show({
        description: "Please enter a valid 10-digit sender's phone number",
        ...toastErrorConfig,
      });
      return;
    }

    if (!localInputs.pickUpDate1) {
      toast.show({
        description: "Please enter a pickup date",
        ...toastErrorConfig,
      });
      return;
    }

    if (localInputs.pickUpDate1 < today) {
      toast.show({
        description: "Pickup date can not be in the past",
        ...toastErrorConfig,
      });
      return;
    }

    Object.entries(localInputs).forEach(([key, value]) => {
      dispatch(setInputValue({ key, value }));
    });

    navigate(
      location.pathname.includes("request-edit")
        ? `/request-edit/3/${id}`
        : id
        ? `/edit/3/${id}`
        : "/create/3"
    );
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const prevStep = () => {
    navigate(
      location.pathname.includes("request-edit")
        ? `/request-edit/1/${id}`
        : id
        ? `/edit/1/${id}`
        : "/create/1"
    );
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const getDateDifference = (date1, date2) => {
    const diffTime = new Date(date2) - new Date(date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const cancel = () => {
    navigate("/orders");
  };

  const Directions = () => {
    const map = useMap();
    const routesLibrary = useMapsLibrary("routes");
    const [directionsService, setDirectionService] = useState();
    const [directionsRenderer, setDirectionsRenderer] = useState();
    const [routes, setRoutes] = useState([]);

    useEffect(() => {
      if (!routesLibrary || !map) return;
      setDirectionService(new routesLibrary.DirectionsService());
      setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map }));
    }, [routesLibrary, map, pickUpLocation, deliveryLocation]);

    useEffect(() => {
      if (!directionsService || !directionsRenderer) return;
      const dirSer = new routesLibrary.DirectionsService();
      const dirRenderer = new routesLibrary.DirectionsRenderer({ map });
      // dirService
      dirSer
        .route({
          origin: pickUpLocation,
          destination: deliveryLocation,
          travelMode: google.maps.TravelMode.DRIVING,
          provideRouteAlternatives: false,
        })
        .then((response) => {
          dirRenderer.setDirections(response);
          setRoutes(response.routes);
        });
    }, [directionsService, directionsRenderer]);
  };

  const MapD = () => {
    return (
      <APIProvider
      // apiKey={GOOGLE_API_KEY}
      >
        <Map
          style={{ height: 400, width: "100%" }}
          defaultCenter={
            pickUpLocationCoordinates.lng ? pickUpLocationCoordinates : position
          }
          defaultZoom={10}
        >
          <Directions />
        </Map>
      </APIProvider>
    );
  };

  const handleCheckboxClick = (location) => {
    if (location === "pickup" && wasPickupChecked) {
      setModalLocation("pickup");
      setShownCancelPINModal(true);
    } else {
      // Directly toggle without modal
      if (location === "pickup") {
        dispatch(
          setInputValue({ key: "pickUpPINRequired", value: !pickUpPINRequired })
        );
        setWasPickupChecked(!pickUpPINRequired);
      }
    }
  };

  const handleCancelPIN = () => {
    // Uncheck the box when user confirms PIN cancellation
    if (modalLocation === "pickup") {
      dispatch(setInputValue({ key: "pickUpPINRequired", value: false }));
      setWasPickupChecked(false);
    } else if (modalLocation === "delivery") {
      dispatch(setInputValue({ key: "deliveryPINRequired", value: false }));
      setWasDeliveryChecked(false);
    }
    setShownCancelPINModal(false);
  };

  const handleCloseModal = () => {
    setShownCancelPINModal(false);
  };

  const CancelPINAlert = () => {
    return (
      <AlertDialog
        isOpen={shownCancelPINModal}
        onClose={handleCloseModal}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>
            <Text>
              Cancel {modalLocation === "pickup" ? "pickup" : "delivery"} PIN
            </Text>
          </AlertDialog.Header>
          <AlertDialog.Body>
            <Text>
              By choosing to cancel the PIN code, you acknowledge and accept
              full responsibility for ensuring the
              {modalLocation === "pickup" ? " pickup" : " delivery"}
            </Text>
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <Button.Group space={2}>
              <Button
                style={styles.cancel}
                variant="cancel"
                onPress={handleCloseModal}
              >
                Go back
              </Button>
              <Button
                variant="fatal"
                style={styles.dcButton}
                onPress={handleCancelPIN}
              >
                Cancel PIN
              </Button>
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const handleChange = (key, value) => {
    setLocalInputs((prev) => ({
      ...prev, // This spread operator retains the previous state properties
      [key]: value,
    }));
    setIsEdited(true);
  };

  const handleSelectPlace = (place) => {
    setLocalInputs((prevState) => ({
      ...prevState,
      placeName: place.placeName,
      pickUpLocation: place.address,
      senderContactName: place.fullName,
      senderContactPhone: place.phone,
      senderContactEmail: place.email,
      checkboxSender: false,
    }));
    setIsDropdownVisible(false);
    setIsEdited(false);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      if (localInputs.placeName.trim()) {
        const filtered = pickupPlaces.filter((place) => {
          place.placeName
            .toLowerCase()
            .includes(localInputs.placeName.toLowerCase()) ||
            place.address
              .toLowerCase()
              .includes(localInputs.placeName.toLowerCase());
        });
        setFilteredPlaces(filtered);
      }
    }, 300); // Debounce for 300 milliseconds

    return () => {
      clearTimeout(handler);
    };
  }, [localInputs.placeName, pickupPlaces]);

  useEffect(() => {
    if (profileOrder && profileOrder.status === "pickedUp") {
      navigate(
        location.pathname.includes("request-edit")
          ? `/request-edit/3/${id}`
          : id
          ? `/edit/3/${id}`
          : "/create/3"
      );
      return;
    }
  }, []);

  useEffect(() => {
    if (localInputs.placeName === "" && isDropdownVisible) {
      setFilteredPlaces(pickupPlaces);
    }
  }, [localInputs.placeName, isDropdownVisible]);

  const handleDeletePlace = async (place) => {
    try {
      await dispatch(deletePlace(place.id)).unwrap();
      dispatch(removePlaceById(place.id));
    } catch (error) {
      console.log("error", error);
      toast.show({
        description: "Failed to delete place",
        ...toastErrorConfig,
      });
    }
  };

  return (
    <>
      <View style={styles.container}>
        <Text style={styles.h1}>
          {location.pathname.includes("request-edit")
            ? "Request edit"
            : id
            ? "Edit order"
            : "Create order"}
        </Text>
        <View style={styles.stepper}>
          <Stepper currentStep={2} />
        </View>
        <View keyboardShouldPersistTaps={"handled"}>
          <Text
            style={{ fontWeight: "bold", fontSize: 18, marginVertical: 10 }}
          >
            Pickup information
          </Text>
          {pickupPlaces && pickupPlaces.length > 0 && (
            <View>
              <FormControl.Label>
                Prefill with saved place (optional)
              </FormControl.Label>
              <View
                style={{
                  backgroundColor: "#F5F5F5", // Light gray background
                  borderRadius: 12,
                }}
              >
                <Input
                  placeholder="Type to find saved place..."
                  value={localInputs.placeName}
                  onChangeText={(text) => {
                    handleChangePlaceName(text);
                    if (!isDropdownVisible) {
                      setIsDropdownVisible(true); // Ensure dropdown is visible when typing starts
                    }
                  }}
                  onFocus={() => {
                    setIsDropdownVisible(true);
                  }}
                  onBlur={() =>
                    setTimeout(() => {
                      setIsDropdownVisible(false);
                    }, 100)
                  }
                  InputLeftElement={
                    <View style={styles.leftIcon}>
                      <SearchIcon />
                    </View>
                  }
                />
              </View>
            </View>
          )}
          {isDropdownVisible && (
            <View
              style={styles.dropdownContainer}
              maxHeight={screenHeight * 0.5}
            >
              <ScrollView>
                {filteredPlaces.length > 0 ? (
                  filteredPlaces.map((place) => (
                    <Pressable
                      key={place.id}
                      onPress={() => handleSelectPlace(place)}
                    >
                      <View style={styles.dropdownItem}>
                        <View>
                          <Text style={{ flex: 1, fontWeight: "bold" }}>
                            {place.placeName}
                          </Text>
                          <Text style={{ flex: 1 }}>{place.address}</Text>
                        </View>
                        <Text
                          onPress={() => handleDeletePlace(place)}
                          style={{ color: "blue", alignContent: "center" }}
                        >
                          Delete
                        </Text>
                      </View>
                    </Pressable>
                  ))
                ) : (
                  <View style={styles.dropdownItem}>
                    <Text>No saved places found</Text>
                  </View>
                )}
              </ScrollView>
            </View>
          )}
          <View style={styles.divider} />
          <FormControl.Label>Pickup Location</FormControl.Label>
          <View
            style={[
              styles.dateButtonContainer,
              profileOrder &&
                profileOrder.status === "pickedUp" && {
                  backgroundColor: "#F0F0F0",
                },
              {
                borderColor: isAddressFocused ? "#1DCE80" : "#D9D9D9",
                backgroundColor: isAddressFocused ? "#E9F8EF" : "#FFFFFF",
                outline: isAddressFocused ? "1px solid #1DCE80" : "none",
              },
            ]}
          >
            <Geo />
            <Autocomplete
              style={{
                width: "100%",
                border: 0,
                paddingLeft: 14,
                outline: "none",
                marginBottom: 8,
                fontSize: "16px",
                backgroundColor: isAddressFocused ? "#E9F8EF" : "#FFFFFF",
              }}
              types={["address"]}
              fields={["address_components", "geometry.location"]}
              placeholder={localInputs.pickUpLocation || "Search"}
              onPlaceSelected={(details) => {
                if (details?.geometry?.location) {
                  const pickLocation = JSON.parse(
                    JSON.stringify(details?.geometry?.location)
                  );
                  pickLocation.title = "Pick Up Location";
                  setPickUpLocationCoordinates(pickLocation);
                  handleChange("pickUpLocation", details?.formatted_address);
                }
              }}
              options={{
                types: ["address"],
                componentRestrictions: { country: "us" },
              }}
              disabled={profileOrder && profileOrder.status === "pickedUp"}
              onFocus={() => setIsAddressFocused(true)}
              onBlur={() => setIsAddressFocused(false)}
            />
          </View>
          <View style={styles.row}>
            <Checkbox
              isDisabled={profileOrder && profileOrder.status === "pickedUp"}
              value="checkbox"
              isChecked={!pickUpPINRequired}
              onChange={() => handleCheckboxClick("pickup")}
              accessibilityLabel="checkbox"
            />
            <Text style={styles.textContainer}>PIN not required at pickup</Text>
          </View>
          <Text style={{ fontWeight: "bold", fontSize: 18 }}>
            Sender information
          </Text>
          <View style={styles.row}>
            <Center>
              <Checkbox
                value="checkbox"
                isChecked={localInputs.checkboxSender}
                // defaultIsChecked={localInputs.checkboxSender}
                onChange={(state) => {
                  handleChange("checkboxSender", state);
                  if (state) {
                    handleChange("senderContactName", fullName);
                    handleChange("senderContactEmail", email);
                    handleChange("senderContactPhone", phone);
                  } else {
                    handleChange("senderContactName", "");
                    handleChange("senderContactEmail", "");
                    handleChange("senderContactPhone", "");
                  }
                }}
                accessibilityLabel="checkbox"
              />
            </Center>

            <Text style={styles.textContainer}>
              Will the account owner be the sender?
            </Text>
          </View>
          <FormControl.Label>Sender Name</FormControl.Label>
          <Input
            value={localInputs.senderContactName}
            onChangeText={(value) => {
              handleChange("senderContactName", value);
            }}
            type="text"
            isDisabled={localInputs.checkboxSender}
            InputLeftElement={
              <View style={styles.leftIcon}>
                <User />
              </View>
            }
            placeholder="Full Name"
          />
          <View style={styles.divider} />
          <FormControl.Label>Sender Email</FormControl.Label>
          <Input
            value={localInputs.senderContactEmail}
            onChangeText={(value) => {
              handleChange("senderContactEmail", value);
            }}
            textContentType="emailAddress"
            keyboardType="email-address"
            autoCapitalize="none"
            type="email"
            autoCorrect={false}
            autoCompleteType="email"
            isDisabled={localInputs.checkboxSender}
            InputLeftElement={
              <View style={styles.leftIcon}>
                <Email />
              </View>
            }
            placeholder="Email Address"
          />
          <View style={styles.divider} />
          <FormControl.Label>Sender Phone Number</FormControl.Label>
          <View
            style={[
              {
                flexDirection: "row",
                alignItems: "center",
                borderRadius: 12,
                borderColor: isSenderPhoneFocused ? "#1DCE80" : "#D9D9D9",
                backgroundColor: isSenderPhoneFocused ? "#E9F8EF" : "FAFAFA",
                borderWidth: 1,
                height: 46,
                width: "100%",
                paddingHorizontal: 12,
                outline: isSenderPhoneFocused ? "1px solid #1DCE80" : "none",
                opacity: localInputs.checkboxSender ? 0.5 : 1,
              },
            ]}
          >
            <View style={{ marginRight: 10 }}>
              <Phone />
            </View>
            <Text>+1 </Text>
            <PhoneInput
              value={localInputs.senderContactPhone}
              onChange={(value) => {
                handleChange("senderContactPhone", value);
              }}
              type="tel"
              maxLength="14"
              placeholder="(222) 333-4455"
              defaultCountry="US"
              style={{
                paddingLeft: 0,
                outline: "none",
                flex: 1,
                borderWidth: 0,
                backgroundColor: isSenderPhoneFocused ? "#E9F8EF" : "white",
                fontSize: 16,
              }}
              onFocus={() => setIsSenderPhoneFocused(true)}
              onBlur={() => setIsSenderPhoneFocused(false)}
              disabled={localInputs.checkboxSender}
            />
          </View>
          <FormControl.Label style={{ marginTop: 20 }}>
            Remember this info if you place orders frequently from here
          </FormControl.Label>
          <View style={{ width: 127 }}>
            <Button
              isDisabled={
                !active ||
                !isEdited ||
                !(pickUpLocation || localInputs.pickUpLocation) ||
                !localInputs.senderContactName ||
                !localInputs.senderContactEmail ||
                !localInputs.senderContactPhone
              }
              onPress={handleOpenSavePlaceModal}
              variant="outline"
            >
              Save place
            </Button>
          </View>
          {/* {!!pickUpLocation?.length && <MapD />}  */}
          <Text
            style={{ fontWeight: "bold", fontSize: 18, marginVertical: 10 }}
          >
            Desired date for pickup
          </Text>
          {/* <View style={styles.divider} /> */}
          <FormControl.Label>Pick Up Date</FormControl.Label>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View>
              <View
                style={[
                  styles.inputBorder,
                  profileOrder &&
                    profileOrder.status === "pickedUp" && {
                      backgroundColor: "#F0F0F0",
                    },
                ]}
              >
                <input
                  style={{
                    borderWidth: 0,
                    color: "#818181",
                    fontSize: 16,
                    backgroundColor: "transparent",
                  }}
                  onChange={(e) => {
                    const selectedDate = e.target.value;
                    const today = new Date().toISOString().split("T")[0];

                    if (selectedDate < today) {
                      toast.show({
                        description:
                          "Please select a valid date (today or later)",
                        ...toastErrorConfig,
                      });
                      return;
                    }
                    setLocalInputs((prev) => ({
                      ...prev,
                      ["pickUpDate1"]: selectedDate,
                    }));
                    if (localInputs.deliveryDate1) {
                      const diffDays = getDateDifference(
                        selectedDate,
                        localInputs.deliveryDate1
                      );
                      if (
                        diffDays > 12 ||
                        selectedDate > localInputs.deliveryDate1
                      ) {
                        setLocalInputs((prev) => ({
                          ...prev,
                          ["deliveryDate1"]: null,
                        }));
                        toast.show({
                          description:
                            "Delivery date must be within 12 days after pickup. Delivery date was reset",
                          ...toastWarningConfig,
                        });
                      }
                    }
                  }}
                  value={localInputs.pickUpDate1 || ""}
                  type="date"
                  name="pickUpDate1"
                  min={new Date().toISOString().split("T")[0]}
                  disabled={profileOrder && profileOrder.status === "pickedUp"}
                />
              </View>
            </View>
            <View style={styles.checkbox}>
              <Checkbox
                isDisabled={profileOrder && profileOrder.status === "pickedUp"}
                value="checkbox"
                isChecked={localInputs.flexiblePickupDate}
                onChange={(state) =>
                  setLocalInputs((prev) => ({
                    ...prev,
                    ["flexiblePickupDate"]: state,
                  }))
                }
                accessibilityLabel="checkbox"
              />
              <Popover
                trigger={(triggerProps) => {
                  return (
                    <Pressable {...triggerProps}>
                      <View style={{ flexDirection: "row" }}>
                        <Text style={styles.textContainer}>Flexible Date </Text>
                        <IoAlertCircleOutline
                          size={18}
                          style={{ cursor: "pointer" }}
                        />
                      </View>
                    </Pressable>
                  );
                }}
                placement="top"
              >
                <Popover.Content maxWidth="350px">
                  <Popover.Arrow bg="gray.100" />
                  <Popover.CloseButton />
                  <Popover.Header bg="gray.100">
                    Flexible Pickup Date
                  </Popover.Header>
                  <Popover.Body bg="gray.100">
                    Choosing a flexible date allows the carrier to plan their
                    route, potentially leading to a more cost-effective
                    transportation offer for you.
                  </Popover.Body>
                </Popover.Content>
              </Popover>
            </View>
          </View>
          <FormControl.Label style={{ marginTop: 4 }}>
            Please ensure the delivery date is within 12 days after the pickup
            date.
          </FormControl.Label>

          <View style={styles.emptySpace} />
          <BottomNavigator
            nextOnPress={nextStep}
            nextText={"Next"}
            prevOnPress={
              profileOrder && profileOrder.status === "pickedUp"
                ? cancel
                : prevStep
            }
            prevText={
              profileOrder && profileOrder.status === "pickedUp"
                ? "Cancel"
                : "Previous"
            }
          />
        </View>
      </View>
      <CancelPINAlert />
      <AlertDialog
        isOpen={isModalVisible}
        onClose={handleCloseSavePlaceModal}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Name Your Pickup Place</AlertDialog.Header>
          <AlertDialog.Body>
            <Input
              placeholder="Enter a name for the place"
              value={localInputs.placeName}
              onChangeText={handleChangePlaceName}
              autoFocus
            />
            <View style={{ height: 20 }}></View>
            <Text>{localInputs.pickUpLocation}</Text>
            <View my={2}>
              <Divider bg="gray.300" thickness="1" />
            </View>
            <Text>{localInputs.senderContactName}</Text>
            <View my={2}>
              <Divider bg="gray.300" thickness="1" />
            </View>
            <Text>{localInputs.senderContactEmail}</Text>
            <View my={2}>
              <Divider bg="gray.300" thickness="1" />
            </View>
            <Text>{formatPhone(localInputs.senderContactPhone)}</Text>
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancelButton}
                variant="cancel"
                onPress={handleCloseSavePlaceModal}
              >
                Cancel
              </Button>
              <Button
                style={styles.confirmButton}
                onPress={handleConfirmSavePlaceModal}
              >
                Confirm
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    </>
  );
}
