import * as React from "react";
import {
  Center,
  Button,
  Checkbox,
  FormControl,
  Input,
  Text,
  View,
  WarningOutlineIcon,
  Box,
  useToast,
  Modal,
  Spinner,
  Pressable,
} from "native-base";
import styles from "./styles";
import { Email, Phone } from "../../assets/images/svg";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../../networking/auth/authSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { isMobile } from "../../config/constants";
import PhoneInput from "react-phone-number-input/input";
import { toastErrorConfig } from "../../config/constants";
import { verifyCredentialToEdit } from "../../networking/auth/authApi";

export default function SignUpScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.pathname;
  const toast = useToast();
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [checked, setChecked] = React.useState(false);
  const [isPhoneFocused, setIsPhoneFocused] = useState(false);
  const { userToken } = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (userToken) {
      navigate("/orders");
    }
  }, [userToken]);

  const goToSignIn = () => {
    if (type === "/signup/email") {
      navigate("/signin/email");
    } else {
      navigate("/signin/phone");
    }
  };

  const confirmCode = async () => {
    dispatch(
      setUser({
        email,
        phone,
      })
    );
    if (!checked) {
      toast.show({
        description: "Please review our Terms of Service and Privacy Policy",
        ...toastErrorConfig,
      });
      return;
    }
    if ((email || phone) && checked) {
      const verificationData = {};
      let dataType = "";

      if (type === "/signup/email") {
        const englishOnlyRegex = /^[\x00-\x7F]+$/; // Matches only ASCII characters (English letters and standard symbols)
        if (!englishOnlyRegex.test(email)) {
          toast.show({
            description: "Email must contain English characters only",
            ...toastErrorConfig,
          });
          return;
        }
        if (!email.includes("@") || !email.includes(".")) {
          toast.show({
            description: "Email is invalid",
            ...toastErrorConfig,
          });
          return;
        }
        verificationData.email = email;
        dataType = "email";
      }
      if (type === "/signup/phone") {
        const isValidPhone = phone && phone.replace(/\D/g, "").length === 11;

        if (!isValidPhone) {
          toast.show({
            description: "Please enter a valid 10-digit phone number.",
            ...toastErrorConfig,
          });
          return;
        }
        verificationData.phone = phone;
        dataType = "phone";
      }

      setIsLoading(true);

      dispatch(verifyCredentialToEdit({ dataType, verificationData }))
        .unwrap()
        .then(() => {
          setIsLoading(false);
          if (type === "/signup/email" && email && checked) {
            navigate(`/confirm/signup1/email/${email}`);
          }
          if (type === "/signup/phone" && phone && checked) {
            navigate(`/confirm/signup1/phone/${phone}`);
          }
        })
        .catch((error) => {
          setIsLoading(false);
          if (error.status === 500) {
            toast.show({
              description: `The ${dataType} provided is not valid. Please check and try again.`,
              ...toastErrorConfig,
            });
          } else if (error.status === 409) {
            toast.show({
              description: `This ${dataType} belongs to different account. Please try a different one.`,
              ...toastErrorConfig,
            });
          } else {
            toast.show({
              description:
                "An unexpected error occurred. Please try again later.",
              ...toastErrorConfig,
            });
          }
        });
    }
  };

  return (
    <>
      <Box style={styles.wrapper}>
        <View style={isMobile() ? styles.containerMobile : styles.container}>
          <Center>
            <Text fontSize="2xl" bold>
              Sign Up
            </Text>
            <Text mt="5px" mb="10px" fontSize="md">
              Create account and enjoy all services
            </Text>
          </Center>
          <FormControl>
            {type === "/signup/email" && (
              <>
                <FormControl.Label>Email</FormControl.Label>
                <Input
                  autoFocus
                  textContentType="emailAddress"
                  keyboardType="email-address"
                  autoCapitalize="none"
                  type="email"
                  autoCorrect={false}
                  autoCompleteType="email"
                  value={email}
                  onChangeText={setEmail}
                  InputLeftElement={
                    <View style={styles.leftIcon}>
                      <Email />
                    </View>
                  }
                  placeholder="Email Address"
                />
              </>
            )}
            {type === "/signup/phone" && (
              <>
                <FormControl.Label>Phone Number</FormControl.Label>
                <View
                  style={[
                    {
                      flexDirection: "row",
                      alignItems: "center",
                      borderRadius: 12,
                      borderColor: isPhoneFocused ? "#1DCE80" : "#D9D9D9",
                      backgroundColor: isPhoneFocused ? "#E9F8EF" : "FAFAFA",
                      borderWidth: 1,
                      height: 46,
                      width: "100%",
                      paddingHorizontal: 12,
                      outline: isPhoneFocused ? "1px solid #1DCE80" : "none",
                    },
                  ]}
                >
                  <View style={{ marginRight: 10 }}>
                    <Phone />
                  </View>
                  <Text>+1 </Text>
                  <PhoneInput
                    value={phone}
                    onChange={setPhone}
                    type="tel"
                    maxLength="14"
                    placeholder="(222) 333-4455"
                    defaultCountry="US"
                    style={{
                      paddingLeft: 0,
                      outline: "none",
                      flex: 1,
                      borderWidth: 0,
                      backgroundColor: isPhoneFocused ? "#E9F8EF" : "#FAFAFA",
                      fontSize: 16,
                    }}
                    onFocus={() => setIsPhoneFocused(true)}
                    onBlur={() => setIsPhoneFocused(false)}
                  />
                </View>
              </>
            )}
            <View style={styles.divider} />
            <View style={styles.row}>
              <Center>
                <Checkbox
                  value="checkbox"
                  key="terms-checkbox"
                  onChange={(state) => {
                    setChecked(state);
                  }}
                  accessibilityLabel="checkbox"
                />
              </Center>

              <Text style={styles.textContainer} fontSize="sm">
                I agree to the company{" "}
                <Text style={styles.actionText}>Terms of Service</Text> and{" "}
                <Text style={styles.actionText}>Privacy Policy</Text>
              </Text>
            </View>
          </FormControl>
          <Button style={styles.button} onPress={confirmCode}>
            Continue
          </Button>
          <View style={styles.divider} />
          <View>
            <Text fontSize="sm">
              Already have an account?{" "}
              <Pressable style={styles.actionText} onPress={goToSignIn}>
                Sign In
              </Pressable>
            </Text>
          </View>
          <View style={styles.divider} />
        </View>
      </Box>
      {isLoading && (
        <Modal
          isOpen={isLoading}
          size="full"
          backdropOpacity={0.9}
          useRNModal={true}
        >
          <View
            style={{
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Spinner size="lg" color="#0000ff" />
          </View>
        </Modal>
      )}
    </>
  );
}
