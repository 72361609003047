const styles = {
  center: {
    justifyContent: "center",
    alignItems: "center",
  },
  forgotPassword: {
    marginLeft: "auto",
    marginTop: 4,
  },
  myProfileTitle: {
    fontSize: 24,
    fontWeight: "700",
    lineHeight: 30,
    paddingVertical: 20,
  },
  container: {
    flex: 1,
    paddingHorizontal: 20,
  //  paddingTop: 20,
    paddingBottom: 20,
    maxWidth: 400,
    minWidth: "60%",
  },
  changePage: {
    paddingBottom: 30,
    marginTop: 10,
  },
  actionText: {
    fontWeight: "600",
    color: "#1DCE80",
  },
  leftIcon: {
    marginLeft: 12,
  },
  rightIcon: {
    marginRight: 12,
  },
  button: {
    marginTop: "auto",
  },
  divider: {
    height: 16,
  },
  bottomSpace: {
    height: 100,
  },
  row: {
    flexDirection: "row",
  },
  textContainer: {
    flexShrink: 1,
    marginLeft: 8,
  },
};

export default styles;
