const styles = {
  center: {
    justifyContent: "center",
  },
  container: {
    flex: 1,
    paddingHorizontal: 20,
    // paddingTop: 20,
    paddingBottom: 20,
    maxWidth: 800,
    minWidth: "300px",
  },
  subHeaderWrapper: {
    flexDirection: "row",
    marginBottom: 20,
    marginTop: 20,
    justifyContent: "space-between",
  },
  actionText: {
    fontWeight: "700",
    color: "#1DCE80",
    fontSize: 16,
    marginLeft: 20,
  },
  myProfileTitle: {
    fontSize: 24,
    fontWeight: "700",
    lineHeight: 30,
  },
  infoContainer: {
    borderWidth: 1,
    borderColor: "#E5E5E5",
    borderRadius: 8,
    paddingHorizontal: 16,
    paddingVertical: 20,
    marginBottom: 12,
    minWidth: 300,
  },
  rowHeader: {
    color: "#737373",
    fontWeight: "500",
    fontSize: 18,
  },
  rowText: {
    color: "#525252",
    fontWeight: "400",
    fontSize: 16,
  },
  divider: {
    backgroundColor: "#E5E5E5",
    height: 1,
    marginVertical: 8,
  },
  paymentRow: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  icon: {
    marginRight: 8,
    color: "black"
  },
  textButton: {
    color: "#1DCE80",
    fontWeight: "700",
    fontSize: 16,
  },
  emptySpace: {
    height: 50,
  },
  header: {
    fontWeight: "700",
    fontSize: 16,
    color: "#171717",
    marginTop: "auto",
    marginLeft: 8,
  },
  buttonsRow: {
    flexDirection: "row",
    width: 200,
  },
  cancel: {
    width: 96,
    marginRight: 8,
    borderWidth: 1,
    borderColor: "#000",
  },
  deleteButton: {
    width: 96,
  },
  leftIcon: {
    marginLeft: 12,
  },
  rightIcon: {
    marginRight: 12,
  },
  confirmDeletionText: {
    fontSize: 14,
    color: "#737373",
    marginTop: 4,
    marginBottom: 2,
  },
  editFields: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  editPlaceInput: {
    marginBottom: 4
  },
  divider: {
    height: 8,
  },
  addressContainer: {
    flex: 1,
    //marginRight: 5,
    borderWidth: 1,
    borderRadius: 12,
    borderColor: "#D9D9D9",
    flexDirection: "row",
    paddingTop: 12,
    paddingRight: 6,
    // paddingLeft: 12,
    paddingBottom: 4,
  },
};

export default styles;
