const styles = {
    wrapper: {
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        flex: 1,
      },
      container: {
        padding: 20,
        justifyContent: "center",
        maxWidth: 400,
        width: "90%",
        backgroundColor: "#FAFAFA",
        borderRadius: 16,
        boxShadow: "0px 1px 2.22px rgba(0, 0, 0, 0.25)"
      },
    inputOpt: {
        fontSize: 16,
        height: 46,
        width: 46,
        border: "1px solid #E5E5E5",
        borderRadius: 12,
        appearance: "none",
        margin: 4,
      },
      buttonContainer: {
        marginTop: 20,
        marginBottom: 20,
        width: "100%",
      },
}

export default styles;
