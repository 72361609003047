import React, { useState, useEffect } from "react";
import axios from "axios";
import { API_URL } from "../../config";
import { useParams } from "react-router-dom";
import { useToast, View, Text, Box, Button, Divider } from "native-base";
import { toastErrorConfig } from "../../config/constants";
import OtpInput from "react-otp-input";
import styles from "./styles";

function VerifyLink() {
  const [code, setCode] = useState("");
  const { id } = useParams();
  const toast = useToast();
  const [isVerified, setIsVerified] = useState(false);
  const [PIN, setPIN] = useState("");

  useEffect(() => {
    (async () => {
      try {
        const { data } = await axios.get(`${API_URL}/api/v1/link/${id}/getPIN`);
        setPIN(data);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [id]);

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${API_URL}/api/v1/link/${id}/verify-carrier`,
        {
          code,
        }
      );
      if (response.data) {
        setIsVerified(true);
      } else {
        toast.show({
          description: "Invalid code provided",
          ...toastErrorConfig,
        });
      }
    } catch (error) {
      console.log("error", error);
      if (error.status === 409 || error.status === 404) {
        toast.show({
          description: "Link is not valid",
          ...toastErrorConfig,
        });
      } else {
        toast.show({
          description: "Something went wrong",
          ...toastErrorConfig,
        });
      }
    }
  };

  return (
    <>
      {isVerified ? (
        <Box style={styles.wrapper}>
          <Box style={styles.container}>
            <Text fontSize="2xl" bold pb={3} textAlign={"center"}>
              Verification Successful
            </Text>
            <Text pb={5} fontSize={18} textAlign={"center"}>
              Your driver has been verified. You may leave this page.
            </Text>
            <View style={{ alignItems: "center" }}>
              <Text fontSize={18}>Please provide this PIN to the driverЖ</Text>
              <Text style={{ color: "#1DCE80", fontSize: 28 }}>{PIN}</Text>
            </View>
          </Box>
        </Box>
      ) : (
        <Box style={styles.wrapper}>
          <Box style={styles.container}>
            <Text fontSize="3xl" bold pb={3} textAlign={"center"}>
              Verification
            </Text>
            <Text fontSize={18}>1) Please provide this PIN to the driver:</Text>
            <View style={{ alignItems: "center", marginBottom: 20 }}>
              <Text style={{ color: "#1DCE80", fontSize: 28 }}>{PIN}</Text>
            </View>
            <View my={2}>
              <Divider bg="gray.300" thickness="1" />
            </View>
            <Text pb={5} fontSize={18}>
              2) Please enter the code you received from the driver:
            </Text>
            <OtpInput
              value={code}
              onChange={setCode}
              numInputs={4}
              inputType="tel"
              containerStyle={{ justifyContent: "center" }}
              inputStyle={styles.inputOpt}
              shouldAutoFocus={true}
              renderInput={(props) => (
                <input
                  {...props}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSubmit(); // Trigger verification on Enter
                    }
                  }}
                />
              )}
            />
            <View style={styles.buttonContainer}>
              <Button onPress={handleSubmit}>Verify</Button>
            </View>
          </Box>
        </Box>
      )}
    </>
  );
}

export default VerifyLink;
