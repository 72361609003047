import * as React from "react";
import {
  AlertDialog,
  Button,
  Center,
  FlatList,
  FormControl,
  Image,
  Pressable,
  Text,
  TextArea,
  View,
  Box,
  Select,
  Spinner,
  useToast,
  ScrollView,
  Modal,
  Input,
  useBreakpointValue,
  Divider,
  SearchIcon,
  Popover,
} from "native-base";
import styles from "./styles";
import {
  AddImage,
  Calendar,
  EmptyOrdersImage,
  Line,
  Location,
  Plus,
  Truck2,
  Upload,
} from "../../assets/images/svg";
import AddressGrey from "../../assets/images/svg/address-grey";
import { useDispatch, useSelector } from "react-redux";
import { getProfile, profileAddFCM } from "../../networking/profile/profileApi";
import { formatStringToDate } from "../../utils";
import { useState, useEffect, useRef, useCallback } from "react";
import {
  disputeOrder,
  rateOrder,
  sendOrderToTMS,
} from "../../networking/orders/ordersApi";
import { useNavigate } from "react-router-dom";
import { isFeeApplied } from "../../utils";
import BidStatus, { textMapper } from "../../components/BidStatus";
import { clearInputValues } from "../../networking/orders/ordersSlice";
import ReactImageUploading from "react-images-uploading";
import instance from "../../networking/axios";
import { API_URL } from "../../config";
import { updateOrder } from "../../networking/profile/profileSlice";
import { toastErrorConfig, toastSuccessConfig } from "../../config/constants";
import {
  cancelOrderWithFee,
  cancelOrderWithoutFee,
  setCarrierProblemStatus,
} from "../../networking/orders/ordersApi";
import { globalLogout } from "../../networking/globalActions";
import PhoneInput from "react-phone-number-input/input";
import { FaThumbsUp, FaThumbsDown } from "react-icons/fa";
import { verifyCredentialToEdit } from "../../networking/auth/authApi";
import { Dimensions } from "react-native";
import { SlOptions } from "react-icons/sl";
import { imageHost } from "../../config";

export default function OrdersListScreen() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const [refreshing, setRefreshing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingOrders, setIsLoadingOrders] = useState(true);
  const [disputeModalIsOpen, setDisputeModalIsOpen] = useState(false);
  const [rateModalIsOpen, setRateModalIsOpen] = useState(false);
  const disputeRef = React.useRef(null);
  const [currentOrder, setCurrentOrder] = useState({});
  const { orders, active, isEmailVerified, isPhoneVerified, email, phone } =
    useSelector((state) => state.profile);
  const [filterStatus, setFilterStatus] = useState("all"); // Filter state
  const [verificationModalOpen, setVerificationModalOpen] = useState(false);
  const [dataType, setDataType] = useState(""); // "email" or "phone"
  const [newValue, setNewValue] = useState("");
  const [isPhoneFocused, setIsPhoneFocused] = useState(false);
  const [shownDisclaimerModal, setShownDisclaimerModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedOrders, setSearchedOrders] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const screenHeight = Dimensions.get("window").height;
  const cancelRef = useRef(null);
  const isMobile = useBreakpointValue({
    base: true, // represents mobile
    sm: false, // represents tablet or larger screens
  });

  useEffect(() => {
    dispatch(clearInputValues());
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    if (!active) {
      toast.show({
        description: "Your account is inactive. Please contact support.",
        ...toastErrorConfig,
      });
    }
  }, []);

  const fetchData = async () => {
    try {
      setIsLoadingOrders(true);
      const user = await dispatch(getProfile({})).unwrap();
      // logout in case user has deleted: true
      if (user.deleted) {
        dispatch(globalLogout());
        return;
      }
    } catch (error) {
      console.log("Error fetching profile:", error);
    } finally {
      setIsLoadingOrders(false);
    }
  };

  const handleRefresh = async () => {
    try {
      setRefreshing(true);
      await dispatch(getProfile({})).unwrap();
    } catch (error) {
      console.log("Error refreshing orders:", error);
    } finally {
      setRefreshing(false);
    }
  };

  const createNewOrder = () => {
    navigate("/create/1");
  };

  const sendOrder = (order) => {
    const today = new Date().toISOString().split("T")[0];
    // Check if the pickup date is before today
    if (order.pickUpDate1 < today) {
      toast.show({
        description: `Pickup date cannot be in the past. Please edit the order and select a valid date`,
        ...toastErrorConfig,
      });
      return;
    }
    const id = order.id;
    dispatch(updateOrder({ id, statusToTMS: "sending" }));
    dispatch(sendOrderToTMS({ data: order }))
      .unwrap()
      .then((sentOrder) => {
        if (sentOrder.success) {
          dispatch(updateOrder({ id, statusToTMS: "sent", status: "auction" }));
          toast.show({
            description: `Order was successfully published`,
            ...toastSuccessConfig,
          });
        } else {
          dispatch(updateOrder({ id: order.id, statusToTMS: "notsent" }));
          toast.show({
            description: sentOrder.message || `Order couldn't be processed`,
            ...toastErrorConfig,
          });
        }
      })
      .catch((err) => {
        dispatch(updateOrder({ id: order.id, statusToTMS: "notsent" }));
        toast.show({
          description: `Something went wrong on our side, your order wasn't published`,
          ...toastErrorConfig,
        });
        console.log("err", err);
      });
  };

  // Parsing the location into city, state, and zip code
  const parseLocation = (address) => {
    const parts = address?.split(", ");
    return {
      city: parts[1] || "",
      state: parts[2]?.split(" ")[0] || "",
      zipCode: parts[2]?.split(" ")[1] || "",
    };
  };

  const OrderItem = ({ order, totalOrders }) => {
    const isInactive = order.statusToTMS === "sending";
    const [selectReasonModalOpen, setSelectReasonModalOpen] = useState(false);
    const [cancelWithoutFeeAlertOpen, setCancelWithoutFeeAlertOpen] =
      useState(false);
    const [cancelWithFeeAlertOpen, setCancelWithFeeAlert] = useState(false);
    const pickupInfo = parseLocation(order.pickUpLocation);
    const deliveryInfo = parseLocation(order.deliveryLocation);
    const responsiveWidthOrderItem = useBreakpointValue({
      base: "90vw",
      md: "45vw",
      lg: "46vw",
      xl: "31vw",
    });

    const handleCancelClick = () => {
      if (isFeeApplied(order.pickUpDate1)) {
        console.log("Fee should be applied");
        setSelectReasonModalOpen(true);
      } else {
        console.log("No fee needed");
        setCancelWithoutFeeAlertOpen(true);
      }
    };

    const handleReasonSelect = (reason) => {
      if (!reason) {
        toast.show({
          description: "Please select the reason",
          ...toastErrorConfig,
        });
        return;
      }
      setSelectReasonModalOpen(false);
      if (reason === "Change of plans") {
        setCancelWithFeeAlert(true);
      } else if (reason === "Carrier didn't arrive") {
        handleCarrierNotArrived();
      }
    };

    const handleCancelWithFee = () => {
      setCancelWithFeeAlert(false);
      dispatch(updateOrder({ id: order.id, isCancelling: true }));
      dispatch(cancelOrderWithFee(order.id))
        .unwrap()
        .then(() => {
          dispatch(updateOrder({ id: order.id, isCancelling: false }));
          setCancelWithFeeAlert(false);
          toast.show({
            description: "Order canceled with a $200 cancellation fee.",
            ...toastSuccessConfig,
          });
          fetchData();
        })
        .catch((error) => {
          dispatch(updateOrder({ id: order.id, isCancelling: false }));
          toast.show({
            description: "We couldn't cancel your order",
            ...toastErrorConfig,
          });
        });
    };

    const handleCancelWithoutFee = () => {
      setCancelWithoutFeeAlertOpen(false);
      dispatch(updateOrder({ id: order.id, isCancelling: true }));
      dispatch(cancelOrderWithoutFee(order.id))
        .unwrap()
        .then(() => {
          dispatch(updateOrder({ id: order.id, isCancelling: false }));
          setCancelWithoutFeeAlertOpen(false);
          toast.show({
            description: "Order canceled with a full refund.",
            ...toastSuccessConfig,
          });
          fetchData();
        })
        .catch((error) => {
          dispatch(updateOrder({ id: order.id, isCancelling: false }));
          toast.show({
            description: "We couldn't cancel your order",
            ...toastErrorConfig,
          });
        });
    };

    const handleCarrierNotArrived = () => {
      dispatch(setCarrierProblemStatus(order.id))
        .unwrap()
        .then(() => {
          dispatch(updateOrder({ id: order.id, status: "carrierProblem" }));
          toast.show({
            description: "Support agent will take care if it soon",
            ...toastSuccessConfig,
          });
        })
        .catch((error) => {
          console.error("Error setting carrier problem status:", error);
          toast.show({
            description: "Failed to update order status. Please try again.",
            ...toastErrorConfig,
          });
        });
    };

    const SelectReasonModal = () => {
      const [reason, setReason] = useState("");
      return (
        <Modal
          isOpen={selectReasonModalOpen}
          onClose={() => setSelectReasonModalOpen(false)}
          useRNModal={true}
        >
          <Modal.Content>
            <Modal.Header>
              {`Are you sure you want to cancel the order #${order.orderName}?`}
            </Modal.Header>
            <Modal.Body>
              <FormControl>
                <Text>Please select the reason for cancellation</Text>
                <FormControl.Label>Reason</FormControl.Label>
                <Select
                  placeholder="Select reason"
                  accessibilityLabel="reason"
                  selectedValue={reason}
                  onValueChange={(reason) => setReason(reason)}
                >
                  <Select.Item
                    label="Change of plans"
                    value="Change of plans"
                  />
                  <Select.Item
                    label="Carrier didn't arrive"
                    value="Carrier didn't arrive"
                  />
                </Select>
              </FormControl>
            </Modal.Body>
            <Modal.Footer>
              <Button
                disabled={!reason}
                onPress={() => {
                  handleReasonSelect(reason);
                }}
              >
                Confirm
              </Button>
              <Button
                style={{ marginTop: 10 }}
                variant="cancel"
                onPress={() => setSelectReasonModalOpen(false)}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal.Content>
        </Modal>
      );
    };

    const CancelWithoutFeeAlert = () => {
      return (
        <AlertDialog
          leastDestructiveRef={cancelRef}
          isOpen={cancelWithoutFeeAlertOpen}
          onClose={() => setCancelWithoutFeeAlertOpen(false)}
          useRNModal={true}
        >
          <AlertDialog.Content>
            <AlertDialog.Header>
              {`Are you sure you want to cancel the order #${order.orderName}?`}
            </AlertDialog.Header>
            <AlertDialog.Body>
              <Text>
                Your order will be saved as draft and carrier won't be able to
                complete it for you. You will receive a full refund to your
                card.
              </Text>
            </AlertDialog.Body>
            <AlertDialog.Footer>
              <Button onPress={handleCancelWithoutFee}>Confirm</Button>
              <Button
                style={{ marginTop: 10 }}
                variant="cancel"
                onPress={() => setCancelWithoutFeeAlertOpen(false)}
              >
                Cancel
              </Button>
            </AlertDialog.Footer>
          </AlertDialog.Content>
        </AlertDialog>
      );
    };

    const CancelWithFeeAlert = () => {
      return (
        <AlertDialog
          leastDestructiveRef={cancelRef}
          isOpen={cancelWithFeeAlertOpen}
          onClose={() => setCancelWithFeeAlert(false)}
          useRNModal={true}
        >
          <AlertDialog.Content>
            <AlertDialog.Header>Final Confirmation</AlertDialog.Header>
            <AlertDialog.Body>
              <Text>
                Your order will be canceled and saved as a draft and you will be
                charged $200 cancellation fee. The rest of your payment will be
                refunded to you card.
              </Text>
            </AlertDialog.Body>
            <AlertDialog.Footer>
              <Button onPress={handleCancelWithFee}>Confirm</Button>
              <Button
                style={{ marginTop: 10 }}
                variant="cancel"
                onPress={() => setCancelWithFeeAlert(false)}
              >
                Cancel
              </Button>
            </AlertDialog.Footer>
          </AlertDialog.Content>
        </AlertDialog>
      );
    };

    return (
      <>
        <View
          width={responsiveWidthOrderItem}
          minWidth="320px"
          style={{ margin: 6 }}
        >
          <Pressable
            onPress={() =>
              !isInactive &&
              !order.isCancelling &&
              navigate(`/order/${order.id}`)
            }
          >
            <View
              style={[
                styles.orderContainer,
                (isInactive || order.isCancelling) && styles.inactiveContainer,
              ]}
            >
              {/* Header section */}
              <View style={{ flexDirection: "row", flex: 1 }}>
                <View style={styles.truckIconContainer}>
                  <Truck2 />
                </View>
                <View
                  style={{
                    marginLeft: 20,
                    flex: 1,
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Text style={styles.orderName}>#{order.orderName}</Text>
                  <View style={styles.auctionStatus}>
                    <BidStatus
                      status={
                        order.statusToTMS === "notsent"
                          ? "notSent"
                          : order.status
                      }
                    />
                  </View>
                </View>
                {(order.status === "pickedUp" ||
                  order.status === "waitingPickUp" ||
                  order.status === "delivered") && (
                  <Popover
                    trigger={(triggerProps) => {
                      return (
                        <Pressable {...triggerProps}>
                          <Text
                            style={{
                              fontSize: 20,
                            }}
                          >
                            <SlOptions />
                          </Text>
                        </Pressable>
                      );
                    }}
                  >
                    <Popover.Content
                      style={{ boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)" }}
                      minWidth="200px"
                      maxWidth="350px"
                      padding={0}
                    >
                      <Popover.Arrow bg="#e4e4e4" />
                      <Popover.Body bg="#e4e4e4">
                        {order.status === "waitingPickUp" &&
                          !order.isCancelling && (
                            <>
                              <Pressable
                                variant="option"
                                style={{ flex: 1, padding: 4, width: "100%" }}
                                isDisabled={!active}
                                onPress={() =>
                                  navigate(`/request-edit/1/${order.id}`)
                                }
                              >
                                Request edit
                              </Pressable>
                              <Pressable
                                variant="option"
                                style={{ flex: 1, padding: 4, width: "100%" }}
                                isDisabled={!active}
                                onPress={() => {
                                  handleCancelClick();
                                }}
                              >
                                Cancel Order
                              </Pressable>
                            </>
                          )}
                        {order.status === "pickedUp" && (
                          <Pressable
                            variant="option"
                            style={{ flex: 1, padding: 4, width: "100%" }}
                            isDisabled={!active}
                            onPress={() =>
                              navigate(`/request-edit/1/${order.id}`)
                            }
                          >
                            Request Edit
                          </Pressable>
                        )}
                        {order.status === "delivered" && (
                          <Pressable
                            isDisabled={!active}
                            style={{ flex: 1, padding: 4, width: "100%" }}
                            onPress={() => {
                              setCurrentOrder(order);
                              setDisputeModalIsOpen(true);
                            }}
                            variant="option"
                          >
                            Start dispute
                          </Pressable>
                        )}
                      </Popover.Body>
                    </Popover.Content>
                  </Popover>
                )}
              </View>
              {/* Location section */}

              <View style={{ gap: 8, flex: 1, marginTop: 4 }}>
                <View style={styles.orderTextContainer}>
                  <Text style={{ marginRight: 6 }}>Pickup:</Text>
                  <Calendar style={styles.icon} />
                  <Text fontSize="14px" numberOfLines={1} ellipsizeMode="tail">
                    {formatStringToDate(order.pickUpDate1)}
                  </Text>
                  <Line style={styles.line} />
                  {/* <AddressGrey style={styles.icon} /> */}
                  <Text style={styles.orderText}>
                    {pickupInfo.city}, {pickupInfo.state} {pickupInfo.zipCode}
                  </Text>
                </View>
                <View style={styles.orderTextContainer}>
                  <Text style={{ marginRight: 4 }}>Delivery:</Text>
                  <Calendar style={styles.icon} />
                  <Text fontSize="14px" numberOfLines={1} ellipsizeMode="tail">
                    {formatStringToDate(order.deliveryDate1)}
                  </Text>
                  <Line style={styles.line} />
                  {/* <AddressGrey style={styles.icon} /> */}
                  <Text style={styles.orderText}>
                    {deliveryInfo.city}, {deliveryInfo.state}{" "}
                    {deliveryInfo.zipCode}
                  </Text>
                </View>
              </View>
              <View style={styles.buttonRow}>
                {order.status === "auction" && (
                  <View style={styles.button}>
                    <Button
                      onPress={() => {
                        navigate(`/bids/${order.id}`);
                      }}
                    >
                      Show Bids
                    </Button>
                  </View>
                )}

                {order.statusToTMS === "sending" && (
                  <View style={styles.sendingContainer}>
                    <Spinner size="small" color="#0000ff" />
                    <Text style={styles.sendingText}>
                      Order is being sent...
                    </Text>
                  </View>
                )}

                {order.isCancelling && (
                  <View style={styles.sendingContainer}>
                    <Spinner size="small" color="#0000ff" />
                    <Text style={styles.sendingText}>
                      Your order is being cancelled...
                    </Text>
                  </View>
                )}

                {/* {order.status === "waitingPickUp" && !order.isCancelling && (
                  <View style={styles.buttonsContainer}>
                    <View style={styles.button}>
                      <Button
                        isDisabled={!active}
                        onPress={() => navigate(`/request-edit/1/${order.id}`)}
                        variant="warning"
                      >
                        Request Edit
                      </Button>
                    </View>
                    <View style={styles.button}>
                      <Button
                        isDisabled={!active}
                        onPress={() => {
                          handleCancelClick();
                        }}
                        variant="fatal"
                      >
                        Cancel Order
                      </Button>
                    </View>
                  </View>
                )} */}

                {(order.status === "waitingPickUpReviewChanges" ||
                  order.status === "pickedUpReviewChanges") && (
                  <View style={styles.button}>
                    <Button
                      isDisabled={!active}
                      onPress={() => {
                        navigate(`/order/${order.id}`);
                      }}
                    >
                      Review Changes
                    </Button>
                  </View>
                )}

                {/* {order.status === "pickedUp" && (
                  <View style={styles.button}>
                    <Button
                      isDisabled={!active}
                      onPress={() => navigate(`/request-edit/1/${order.id}`)}
                      variant="warning"
                    >
                      Request Edit
                    </Button>
                  </View>
                )} */}

                {order.status === "new" &&
                  (order.statusToTMS === "notsent" ||
                    order.statusToTMS === "draft") && (
                    <View style={styles.button}>
                      <Button
                        isDisabled={!active}
                        onPress={() => {
                          setCurrentOrder(order);
                          setShownDisclaimerModal(true);
                        }}
                      >
                        Publish Order
                      </Button>
                    </View>
                  )}

                {order.status === "delivered" && (
                  // <View style={styles.buttonsContainer}>
                  <View style={styles.button}>
                    <Button
                      isDisabled={!active}
                      onPress={() => {
                        setCurrentOrder(order);
                        setRateModalIsOpen(true);
                      }}
                    >
                      Complete Order
                    </Button>
                  </View>

                  //   {order.status === "delivered" && (
                  //     <View style={styles.button}>
                  //       <Button
                  //         isDisabled={!active}
                  //         onPress={() => {
                  //           setCurrentOrder(order);
                  //           setDisputeModalIsOpen(true);
                  //         }}
                  //         variant="fatal"
                  //       >
                  //         Start dispute
                  //       </Button>
                  //     </View>
                  //   )}
                  // </View>
                )}
              </View>
            </View>
          </Pressable>
        </View>
        <SelectReasonModal />
        <CancelWithoutFeeAlert />
        <CancelWithFeeAlert />
      </>
    );
  };

  const DisputeAlert = () => {
    const [disputeText, setDisputeText] = useState("");
    const [images, setImages] = useState([]);

    const onClose = () => {
      setDisputeText("");
      setImages([]);
      setDisputeModalIsOpen(false);
    };

    const sendDispute = () => {
      if (disputeText.trim().length < 20 || disputeText.trim().length > 180) {
        toast.show({
          description: "The text should be at least 20 symbols",
          ...toastErrorConfig,
        });
        return;
      }
      if (!images.length) {
        toast.show({
          description: "Please upload at least one image.",
          ...toastErrorConfig,
        });
        return;
      }
      setDisputeModalIsOpen(false);
      dispatch(
        disputeOrder({
          data: {
            id: currentOrder.id,
            disputeText,
            disputeImages: images,
          },
        })
      )
        .unwrap()
        .then(() => {
          dispatch(updateOrder({ id: currentOrder.id, status: "dispute" }));
          toast.show({
            description: "Order was successfully disputed.",
            ...toastSuccessConfig,
          });
        })
        .catch((error) => {
          toast.show({
            description: "Failed to dispute the order.",
            ...toastErrorConfig,
          });
          console.error("Error disputing order:", error);
        });
    };

    const onChange = async (imageList) => {
      if (images.length + imageList.length > 10) {
        toast.show({
          description: "You can only upload up to 10 images.",
          ...toastErrorConfig,
        });
        return;
      }

      const uploadedImages = await Promise.all(
        imageList.map(async ({ file }) => {
          try {
            const formattedFileName = file.name.replace(/\s+/g, "-");
            const formData = new FormData();
            formData.append("file", file, formattedFileName);

            const result = await instance.post(
              `${API_URL}/api/v1/orders/upload`,
              formData
            );

            if (result?.data?.fileName) {
              return result.data.fileName;
            }
          } catch (error) {
            console.error("Error uploading image:", error);
            toast.show({
              description: "Failed to upload image. Please try again.",
              ...toastErrorConfig,
            });
          }
          return null; // Return null for failed uploads
        })
      );

      // Filter out failed uploads and update state
      const validImages = uploadedImages.filter((url) => url !== null);
      setImages((prev) => [...prev, ...validImages]);
    };

    return (
      <AlertDialog
        leastDestructiveRef={cancelRef}
        isOpen={disputeModalIsOpen}
        onClose={onClose}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Dispute Request</AlertDialog.Header>
          <AlertDialog.Body>
            <FormControl.Label>Dispute Reason</FormControl.Label>
            <TextArea
              value={disputeText}
              onChangeText={setDisputeText}
              h={20}
              placeholder="Please describe what happened"
            />
            <Text style={{ fontSize: 12, color: "#888", marginTop: 5 }}>
              {disputeText.trim().length}/180 characters
            </Text>
            <ScrollView
              horizontal
              showsHorizontalScrollIndicator={false}
              style={{ flexDirection: "row" }}
            >
              {images.map((image, imgIndex) => {
                const imageUrl = `${image.fileName || image}`;
                return (
                  <View
                    key={imgIndex}
                    style={{
                      marginTop: 10,
                      marginRight: 10,
                    }}
                  >
                    <Image
                      source={{ uri: `${imageHost}${imageUrl}` }}
                      alt={`uploaded-${imgIndex}`}
                      style={{
                        width: 100,
                        height: 100,
                        objectFit: "cover",
                        borderRadius: 12,
                        filter: "blur(0.5px)", // Helps with display on smaller resolutions
                      }}
                    />
                    <Pressable
                      onPress={() => {
                        const newImageList = images.filter(
                          (_, i) => i !== imgIndex
                        ); // Filter out the removed image
                        setImages(newImageList);
                      }}
                      style={{
                        position: "absolute",
                        top: -5,
                        right: -5,
                        backgroundColor: "white",
                        width: 30,
                        height: 30,
                        borderRadius: 12,
                        alignItems: "center",
                        justifyContent: "center",
                        shadowColor: "#000",
                        shadowOffset: { width: 0, height: 2 },
                        shadowOpacity: 0.25,
                        shadowRadius: 3.84,
                        elevation: 5,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 16,
                          fontWeight: "bold",
                        }}
                      >
                        ×
                      </Text>
                    </Pressable>
                  </View>
                );
              })}
            </ScrollView>
            <ReactImageUploading
              multiple
              value={images.map((image) => ({
                data_url: `${imageHost}${image}`,
              }))}
              onChange={onChange}
              maxNumber={10}
              dataURLKey="data_url"
            >
              {({ onImageUpload, dragProps }) => (
                <>
                  <Pressable onPress={onImageUpload} {...dragProps}>
                    <View
                      style={{
                        justifyContent: "center",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <AddImage />
                      <Text style={{ marginLeft: 10 }}>Add Image</Text>
                    </View>
                  </Pressable>
                </>
              )}
            </ReactImageUploading>
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancel}
                variant="cancel"
                onPress={onClose}
                ref={cancelRef}
              >
                Cancel
              </Button>
              <Button
                isDisabled={
                  disputeText.trim().length < 20 ||
                  disputeText.trim().length > 180
                }
                style={styles.acButton}
                onPress={sendDispute}
              >
                Send Dispute
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const RateAlert = () => {
    const onClose = () => {
      setRateModalIsOpen(false);
    };
    const rateRef = React.useRef(null);
    const [selected, setSelected] = useState(null); // Track selected button (up or down)
    const [reviewText, setReviewText] = useState("");

    const handleSubmit = () => {
      if (selected === "down" && !reviewText.trim()) {
        // Ensure a written review is provided for thumbs-down
        toast.show({
          description: "Please provide a reason for your negative feedback",
          ...toastErrorConfig,
        });
        return;
      }
      onClose();

      const reviewData = {
        id: currentOrder.id,
        rating: selected === "down" ? 0 : 1,
        carrierID: currentOrder.carrierID,
        review: reviewText.trim(),
      };
      setIsLoading(true);

      dispatch(
        rateOrder({
          data: reviewData,
        })
      )
        .unwrap()
        .then(() => {
          dispatch(
            updateOrder({ id: currentOrder.id, status: "completedAndRated" })
          );
          toast.show({
            description: "Thank you for your feedback!",
            ...toastSuccessConfig,
          });
        })
        .catch((error) => {
          toast.show({
            description: "Failed to submit rating. Please try again.",
            ...toastErrorConfig,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    };

    return (
      <AlertDialog isOpen={rateModalIsOpen} onClose={onClose} useRNModal={true}>
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Complete Order</AlertDialog.Header>
          <AlertDialog.Body>
            Are you sure you want to mark the order as completed? Dispute
            options will no longer be available. We'd welcome your feedback!
            Please rate the carrier and the service.
            <View my={2}>
              <Divider bg="gray.300" thickness="1" />
            </View>
            <View style={styles.buttonsWrapper}>
              {/* Thumbs Up Button */}
              <Button
                style={[
                  styles.thumbButton,
                  selected === "up" && styles.selectedButtonUp,
                ]}
                onPress={() => setSelected("up")}
                _hover={{ bg: "#D1FAE5" }}
                _pressed={{ bg: "#10B981" }}
              >
                <FaThumbsUp
                  size={24}
                  color={selected === "up" ? "white" : "#10B981"}
                />
              </Button>

              {/* Spacer */}
              <View style={styles.spacer}></View>

              {/* Thumbs Down Button */}
              <Button
                style={[
                  styles.thumbButton,
                  selected === "down" && styles.selectedButtonDown,
                ]}
                onPress={() => setSelected("down")}
                _hover={{ bg: "#FEE2E2" }}
                _pressed={{ bg: "#EF4444" }}
              >
                <FaThumbsDown
                  size={24}
                  color={selected === "down" ? "white" : "#EF4444"}
                />
              </Button>
            </View>
            {selected === "down" && (
              <View>
                <TextArea
                  value={reviewText}
                  onChange={(e) => setReviewText(e.target.value)}
                  placeholder={
                    "Please provide a reason for your negative feedback..."
                  }
                />
                <Text style={{ fontSize: 12, color: "#888", marginTop: 5 }}>
                  {reviewText.trim().length}/180 characters
                </Text>
              </View>
            )}
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancel}
                variant="cancel"
                onPress={onClose}
                ref={rateRef}
              >
                Cancel
              </Button>
              <Button
                isDisabled={
                  (selected === "down" &&
                    (reviewText?.trim().length < 20 ||
                      reviewText?.trim().length > 180)) ||
                  !selected
                }
                style={styles.acButton}
                onPress={handleSubmit}
              >
                Submit
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const EmptyComponent = () => {
    return (
      <Box style={styles.wrapper}>
        <View style={styles.emptyContainer}>
          <EmptyOrdersImage />
          <Text style={styles.emptyText}>
            Your order history is empty. Begin by creating your first order and
            discovering the convenience of our services.
          </Text>
        </View>
      </Box>
    );
  };

  const filteredOrders = (orders) => {
    let filtered = orders;

    if (orders.length > 0) {
      {
        filtered = orders.filter((order) => {
          if (filterStatus === "all") {
            return order.status !== "completedAndRated";
          }
          return order.status === filterStatus;
        });
      }

      return filtered.sort((a, b) => {
        const priorityOrder = {
          carrierProblem: 1,
          dispute: 2,
          auction: 3,
          waitingPickUp: 4,
          pickedUp: 5,
          delivered: 6,
          // Default for other statuses not listed
          default: 7,
        };

        const priorityA = priorityOrder[a.status] || priorityOrder.default;
        const priorityB = priorityOrder[b.status] || priorityOrder.default;

        if (priorityA !== priorityB) {
          return priorityA - priorityB; // Sort by priority
        }

        // If priorities are the same, sort by updatedAt
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      });
    }
  };

  const handleCreateOrderClick = () => {
    dispatch(clearInputValues());
    if (!isEmailVerified || !isPhoneVerified) {
      setDataType(!isEmailVerified ? "email" : "phone");
      setNewValue(!isEmailVerified ? email : phone);
      setVerificationModalOpen(true);
    } else {
      createNewOrder();
    }
  };

  const handleVerifySubmit = async () => {
    if (dataType === "email") {
      const englishOnlyRegex = /^[\x00-\x7F]+$/; // Matches only ASCII characters (English letters and standard symbols)
      if (!englishOnlyRegex.test(email)) {
        toast.show({
          description: "Email must contain English characters sonly",
          ...toastErrorConfig,
        });
        return;
      }
      if (!newValue.includes("@") || !newValue.includes(".")) {
        toast.show({
          description: "Email is invalid",
          ...toastErrorConfig,
        });
        return;
      }
    }
    if (dataType === "phone") {
      const isValidPhone =
        newValue && newValue.replace(/\D/g, "").length === 11;

      if (!isValidPhone) {
        toast.show({
          description: "Please enter a valid 10-digit phone number.",
          ...toastErrorConfig,
        });
        return;
      }
    }
    if (!newValue) {
      toast.show({
        description: "Please enter a valid value",
        ...toastErrorConfig,
      });
      return;
    }
    const verificationData =
      dataType === "email" ? { email: newValue } : { phone: newValue };
    dispatch(verifyCredentialToEdit({ dataType, verificationData }))
      .unwrap()
      .then(() => {
        setVerificationModalOpen(false);
        navigate(`/profile/confirm/verify/${dataType}/${newValue}`);
      })
      .catch((error) => {
        if (error.status === 409) {
          toast.show({
            description: `This ${dataType} belongs to different account. Please try a different one.`,
            ...toastErrorConfig,
          });
        } else {
          toast.show({
            description:
              "An unexpected error occurred. Please try again later.",
            ...toastErrorConfig,
          });
        }
      });
  };

  const PublishOrderAlert = () => {
    const onClose = () => {
      setShownDisclaimerModal(false);
    };
    return (
      <AlertDialog
        isOpen={shownDisclaimerModal}
        onClose={onClose}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Publish Order</AlertDialog.Header>
          <AlertDialog.Body>
            Attention! If there is no activity and confirmation of your order
            within 24 hours, your listing will be removed from the board.
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={{ flexDirection: "row", flex: 2 }}>
              <Button
                style={styles.cancelButton}
                variant="cancel"
                onPress={onClose}
              >
                Cancel
              </Button>
              <Button
                style={styles.publishButton}
                onPress={() => {
                  setShownDisclaimerModal(false);
                  sendOrder(currentOrder);
                }}
              >
                Publish
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const handleSelectOrder = (order) => {
    setIsDropdownVisible(false);
    setSearchText("");
    navigate(`/order/${order.id}`);
  };

  const getOrderCountsByStatus = (orders) => {
    return orders.reduce((counts, order) => {
      counts[order.status] = (counts[order.status] || 0) + 1;
      return counts;
    }, {});
  };

  const getActiveOrdersCount = (orders) => {
    return orders.filter((order) => order.status !== "completedAndRated")
      .length;
  };

  const activeOrdersCount = getActiveOrdersCount(orders);

  const orderCounts = getOrderCountsByStatus(orders);

  return (
    <ScrollView style={styles.container} keyboardShouldPersistTaps="handled">
      <View style={styles.subHeaderWrapper}>
        <Text style={styles.myOrdersTitle}>My Orders</Text>
        <View style={styles.createNewOrder}>
          <Button isDisabled={!active} onPress={handleCreateOrderClick}>
            Create Order
          </Button>
        </View>
      </View>
      <View mb={2}>
        <Divider bg="gray.300" thickness="1" />
      </View>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginVertical: 10,
        }}
      >
        <View
          style={{
            marginRight: 5,
            maxWidth: "50%",
          }}
        >
          <Select
            selectedValue={filterStatus}
            flex={1}
            accessibilityLabel="Filter by Status"
            placeholder="Filter by Status"
            onValueChange={(itemValue) => setFilterStatus(itemValue)}
          >
            <Select.Item
              label={`Active ${`(${activeOrdersCount})`}`}
              value="all"
            />
            <Select.Item
              label={`Drafts ${`(${orderCounts["new"] || 0})`}`}
              value="new"
            />
            <Select.Item
              label={`Auction ${`(${orderCounts["auction"] || 0})`}`}
              value="auction"
            />
            <Select.Item
              label={`Waiting ${`(${orderCounts["waitingPickUp"] || 0})`}`}
              value="waitingPickUp"
            />
            <Select.Item
              label={`Picked up ${`(${orderCounts["pickedUp"] || 0})`}`}
              value="pickedUp"
            />
            <Select.Item
              label={`Delivered ${`(${orderCounts["delivered"] || 0})`}`}
              value="delivered"
            />
            <Select.Item
              label={`Disputed ${`(${orderCounts["dispute"] || 0})`}`}
              value="dispute"
            />
            <Select.Item
              label={`Archived ${`(${orderCounts["completedAndRated"] || 0})`}`}
              value="completedAndRated"
            />
          </Select>
        </View>
        <View
          style={{
            marginLeft: 5,
            maxWidth: "50%",
          }}
        >
          <Input
            type="text"
            placeholder="Search orders..."
            value={searchText}
            onChangeText={(value) => {
              setSearchText(value);
              if (!isDropdownVisible) {
                setIsDropdownVisible(true); // Ensure dropdown is visible when typing starts
              }
            }}
            onFocus={() => {
              setIsDropdownVisible(true);
            }}
            onBlur={() => {
              if (isDropdownVisible) {
                setTimeout(() => {
                  setIsDropdownVisible(false);
                }, 100);
              }
            }} // Delays hiding dropdown to allow selection
            InputLeftElement={
              <View style={styles.leftIcon}>
                <SearchIcon />
              </View>
            }
          />
        </View>
      </View>
      {isLoadingOrders ? (
        <Center flex={1} style={{ marginTop: 50 }}>
          <Spinner size="lg" color="#1DCE80" />
          <Text mt={4}>Loading Orders...</Text>
        </Center>
      ) : (
        <View style={{ marginTop: 5 }}>
          {isDropdownVisible && (
            <View
              style={styles.dropdownContainer}
              maxHeight={screenHeight * 0.5}
            >
              <ScrollView>
                {orders
                  .filter((order) => {
                    // Create a searchable string for each order
                    const searchContent = [
                      order.id,
                      order.orderName,
                      order.status,
                      order.pickUpLocation,
                      order.deliveryLocation,
                      order.senderContactName,
                      order.senderContactPhone,
                      order.senderContactEmail,
                      order.recipientContactName,
                      order.recipientContactPhone,
                      order.recipientContactEmail,
                      order.carrierName,
                      order.carrierPhone,
                      order.carrierEmail,
                      order.bidAmount?.toString(),
                      ...order.vehicles.map(
                        (vehicle) =>
                          `${vehicle.vin} ${vehicle.year} ${vehicle.brand} ${vehicle.model}`
                      ),
                    ]
                      .join(" ")
                      .toLowerCase();

                    return searchContent.includes(searchText.toLowerCase());
                  })
                  .map((order) => (
                    <View key={order.id}>
                      <Pressable onPress={() => handleSelectOrder(order)}>
                        <View style={styles.dropdownItem}>
                          <Text>Status: {textMapper[order.status]}</Text>
                          <Text>
                            Vehicles: {order.vehicles[0].year}{" "}
                            {order.vehicles[0].brand} {order.vehicles[0].model}{" "}
                            {order.vehicles.length > 1 &&
                              `(+${order.vehicles.length - 1} more)`}
                          </Text>
                          <Text>
                            Pickup: {order.pickUpLocation} -{" "}
                            {formatStringToDate(order.pickUpDate1)}
                          </Text>
                          <Text>
                            Delivery: {order.pickUpLocation} -{" "}
                            {formatStringToDate(order.deliveryDate1)}
                          </Text>
                        </View>
                      </Pressable>
                    </View>
                  ))}
              </ScrollView>
            </View>
          )}
          <FlatList
            contentContainerStyle={{
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "flex-start",
              justifyContent: isMobile ? "center" : "flex-start",
              // justifyContent: "center",
            }}
            data={filteredOrders(orders)}
            renderItem={({ item }) => (
              <OrderItem
                order={item}
                totalOrders={filteredOrders(orders).length}
              />
            )}
            keyExtractor={(item) => item.id}
            refreshing={refreshing}
            onRefresh={() => {
              handleRefresh;
            }}
            ListEmptyComponent={() => <EmptyComponent />}
            ListFooterComponent={() => <View style={styles.footerBlock} />}
          />
        </View>
      )}
      <Modal
        isOpen={verificationModalOpen}
        useRNModal={true}
        onClose={() => setVerificationModalOpen(false)}
      >
        <Modal.Content maxWidth="400px">
          <Modal.CloseButton />
          <Modal.Header>
            <Text>Verify your {dataType === "email" ? "email" : "phone"}</Text>
          </Modal.Header>
          <Modal.Body>
            <Text style={{ marginBottom: 10 }}>
              Please confirm or edit {dataType} to proceed with order creation.
            </Text>
            {dataType === "email" ? (
              <Input
                type="email"
                placeholder={`Please verify ${dataType}`}
                value={newValue}
                onChangeText={setNewValue}
                keyboardType="email-address"
              />
            ) : (
              <View
                style={[
                  {
                    flexDirection: "row",
                    alignItems: "center",
                    borderRadius: 12,
                    borderColor: isPhoneFocused ? "#1DCE80" : "#D9D9D9",
                    backgroundColor: isPhoneFocused ? "#E9F8EF" : "FAFAFA",
                    borderWidth: 1,
                    height: 46,
                    width: "100%",
                    paddingHorizontal: 12,
                    outline: isPhoneFocused ? "1px solid #1DCE80" : "none",
                  },
                ]}
              >
                <Text>+1 </Text>
                <PhoneInput
                  value={newValue}
                  onChange={setNewValue}
                  type="tel"
                  maxLength="14"
                  placeholder="(222) 333-4455"
                  defaultCountry="US"
                  style={{
                    outline: "none",
                    flex: 1,
                    borderWidth: 0,
                    backgroundColor: "transparent",
                    fontSize: 16,
                  }}
                  onFocus={() => setIsPhoneFocused(true)}
                  onBlur={() => setIsPhoneFocused(false)}
                />
              </View>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onPress={handleVerifySubmit}>Confirm</Button>
            <Button
              style={{ marginTop: 10 }}
              variant="cancel"
              onPress={() => setVerificationModalOpen(false)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <DisputeAlert />
      <RateAlert />
      <PublishOrderAlert />
      <Modal
        isOpen={isLoading}
        size="full"
        backdropOpacity={0.9}
        useRNModal={true}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size="lg" color="#0000ff" />
          {/* <Text style={{ color: "#ffffff", marginTop: 10, fontSize: 20 }}>
            Loading...
          </Text> */}
        </View>
      </Modal>
    </ScrollView>
  );
}
