import { createSlice } from "@reduxjs/toolkit";
import {
  getProfile,
  updateProfile,
  updateProfileEmail,
  updateProfilePhone,
  getOrder,
} from "./profileApi";

const initialState = {
  loading: false,
  fullName: null,
  isEmailVerified: false,
  email: null,
  isPhoneVerified: false,
  phone: null,
  address: {
    state: null,
    city: null,
    zip: null,
    county: null,
    streetAddressLine1: null,
    streetAddressLine2: null,
  },
  orders: [],
  updated: false,
  active: true,
  deleted: false,
  resendCooldownEndTime: null,
  places: []
};

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    clearUpdated(state) {
      state.updated = false;
    },
    clearProfile() {
      return initialState;
    },
    updateOrder(state, action) {
      const updatedOrder = action.payload;
      const index = state.orders.findIndex(
        (order) => order.id === updatedOrder.id
      );
      if (index !== -1) {
        state.orders[index] = { ...state.orders[index], ...updatedOrder };
      }
    },
    updateProfileState(state, action) {
      // Merge the new properties into the existing state
      Object.assign(state, action.payload);
    },
    removeOrderById: (state, action) => {
      const orderId = action.payload;
      state.orders = state.orders.filter((order) => order.id !== orderId);
    },
    removePlaceById(state, action) {
      const placeId = action.payload;
      state.places = state.places.filter((place) => place.id !== placeId);
    },
    setResendCooldown(state, action) {
      state.resendCooldownEndTime = action.payload;
    },
    clearResendCooldown(state) {
      state.resendCooldownEndTime = null;
    },
    setOrderChanges(state, action) {
      const { id, changes } = action.payload;
      const orderIndex = state.orders.findIndex((order) => order.id === id);
      if (orderIndex !== -1) {
        state.orders[orderIndex].orderChanges = changes; // Add changes to the specific order
      }
    },
    clearOrderChanges(state, action) {
      const id = action.payload;
      const orderIndex = state.orders.findIndex((order) => order.id === id);
      if (orderIndex !== -1) {
        delete state.orders[orderIndex].orderChanges; // Remove changes from the specific order
      }
    },
    updateOrderReview(state, action) {
      const { id, review } = action.payload;
      const order = state.orders.find((order) => order.id === id);
      if (order) {
        order.review = review; // Add or update the review for the order
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfile.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      const {
        fullName,
        email,
        phone,
        address,
        orders,
        active,
        deleted,
        isEmailVerified,
        isPhoneVerified,
        places
      } = action.payload;
      state.fullName = fullName;
      state.isEmailVerified = isEmailVerified;
      state.email = email;
      state.isPhoneVerified = isPhoneVerified;
      state.phone = phone;
      state.address = address;
      state.orders = orders;
      state.places = places;
      state.loading = false;
      state.active = active;
      state.deleted = deleted;
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(updateProfile.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      const {
        fullName,
        email,
        phone,
        address,
        orders,
        isEmailVerified,
        isPhoneVerified,
      } = action.payload;
      state.fullName = fullName;
      state.isEmailVerified = isEmailVerified;
      state.email = email;
      state.isPhoneVerified = isPhoneVerified;
      state.phone = phone;
      state.address = address;
      state.orders = orders;
      state.loading = false;
      state.updated = true;
    });
    builder.addCase(updateProfile.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(updateProfilePhone.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(updateProfilePhone.fulfilled, (state, action) => {
      state.phone = action.payload.phone;
      state.updated = true;
      state.loading = false;
    });

    builder.addCase(updateProfileEmail.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(updateProfileEmail.fulfilled, (state, action) => {
      state.email = action.payload.email;
      state.updated = true;
    });
    builder.addCase(getOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOrder.fulfilled, (state, action) => {
      const updatedOrder = action.payload;
      const index = state.orders.findIndex(
        (order) => order.id === updatedOrder.id
      );
      if (index !== -1) {
        state.orders[index] = { ...state.orders[index], ...updatedOrder };
      } else {
        state.orders.push(updatedOrder);
      }
      state.loading = false;
    });
    builder.addCase(getOrder.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const {
  clearUpdated,
  clearProfile,
  updateOrder,
  removeOrderById,
  removePlaceById,
  updateProfileState,
  setResendCooldown,
  clearResendCooldown,
  setOrderChanges,
  clearOrderChanges,
  updateOrderReview,
} = profileSlice.actions;

export default profileSlice.reducer;
