import * as React from "react";
import {
  Button,
  Center,
  Checkbox,
  FormControl,
  Input,
  Text,
  View,
  useToast,
  AlertDialog,
  Pressable,
  ScrollView,
  Popover,
  SearchIcon,
  Divider,
} from "native-base";
import styles from "./styles";
import { Email, Phone, User } from "../../../assets/images/svg";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { carsObj } from "../../../assets/data/carsList";
import { setInputValue } from "../../../networking/orders/ordersSlice";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import BottomNavigator from "../../../components/BottomNavigator";
import PhoneInput from "react-phone-number-input/input";
import {
  toastErrorConfig,
  toastSuccessConfig,
  toastWarningConfig,
} from "../../../config/constants";
import Stepper from "../../../components/stepper";
import Autocomplete from "react-google-autocomplete";
import { Geo } from "../../../assets/images/svg";
import { createPlace } from "../../../networking/orders/ordersApi";
import { Dimensions } from "react-native";
import { IoAlertCircleOutline } from "react-icons/io5";
import { parseLocation, formatPhone } from "../../../utils";
import { deletePlace } from "../../../networking/profile/profileApi";
import { removePlaceById } from "../../../networking/profile/profileSlice";

export default function NOFFourthStepScreen() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  // const [isSenderPhoneFocused, setIsSenderPhoneFocused] = useState(false);
  const [isRecipientPhoneFocused, setIsRecipientPhoneFocused] = useState(false);
  const toast = useToast();
  const [deliveryLocationCoordinates, setDeliveryLocationCoordinates] =
    useState({});
  const [shownCancelPINModal, setShownCancelPINModal] = useState(false);
  const [modalLocation, setModalLocation] = useState(""); // Track whether it's for pickup or delivery
  const orderState = useSelector((state) => state.order);
  const { fullName, email, phone, active, orders, places } = useSelector(
    (state) => state.profile
  );
  const profileOrder = orders.find((order) => order.id === id) || null;
  const { deliveryLocation, deliveryPINRequired } = orderState;
  const [localInputs, setLocalInputs] = useState({
    deliveryLocation: orderState.deliveryLocation || "",
    recipientContactName: orderState.recipientContactName || "",
    recipientContactPhone: orderState.recipientContactPhone || "",
    recipientContactEmail: orderState.recipientContactEmail || "",
    checkboxRecipient: orderState.checkboxRecipient || false,
    placeName1: orderState.placeName1 || "",
    pickUpDate1: orderState.pickUpDate1 || null,
    deliveryDate1: orderState.deliveryDate1 || null,
    flexibleDeliveryDate: orderState.flexibleDeliveryDate || false,
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [filteredPlaces, setFilteredPlaces] = useState([]);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [wasDeliveryChecked, setWasDeliveryChecked] =
    useState(deliveryPINRequired);
  const [isEdited, setIsEdited] = useState(false);
  const screenHeight = Dimensions.get("window").height;
  const [isAddressFocused, setIsAddressFocused] = useState(false);
  const today = new Date().toISOString().split("T")[0];
  const deliveryPlaces = places.filter((place) => place.type === "delivery");

  const handleOpenSavePlaceModal = () => {
    if (!localInputs.deliveryLocation) {
      toast.show({
        description: "Please fill in  delivery location",
        ...toastErrorConfig,
      });
      return;
    }

    if (
      !localInputs.recipientContactName ||
      !localInputs.recipientContactEmail ||
      !localInputs.recipientContactPhone
    ) {
      toast.show({
        description: "Please fill in recipient information to proceed",
        ...toastErrorConfig,
      });
      return;
    }

    if (
      !localInputs.recipientContactEmail.includes("@") ||
      !localInputs.recipientContactEmail.includes(".")
    ) {
      toast.show({
        description: "Recipient email is invalid",
        ...toastErrorConfig,
      });
      return;
    }

    const isValidRecipientPhone =
      localInputs.recipientContactPhone &&
      localInputs.recipientContactPhone.replace(/\D/g, "").length === 11;

    if (!isValidRecipientPhone) {
      toast.show({
        description: "Please enter a valid 10-digit recipient's phone number",
        ...toastErrorConfig,
      });
      return;
    }
    setIsModalVisible(true);
  };

  const handleCloseSavePlaceModal = () => {
    setIsModalVisible(false);
  };

  const addDays = (date, days) => {
    if (!date || isNaN(new Date(date).getTime())) {
      console.error("Invalid date provided:", date);
      return ""; // Return a default value or handle it as needed
    }
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result.toISOString().split("T")[0]; // Returns date in YYYY-MM-DD format
  };

  const handleConfirmSavePlaceModal = async () => {
    const placeData = {
      placeName: localInputs.placeName1,
      fullName: localInputs.recipientContactName,
      phone: localInputs.recipientContactPhone,
      email: localInputs.recipientContactEmail,
      address: localInputs.deliveryLocation,
      type: "delivery",
    };
    try {
      await dispatch(createPlace(placeData)).unwrap();
      toast.show({
        description: "Place saved successfully!",
        ...toastSuccessConfig,
      });
      setIsEdited(false);
      handleCloseSavePlaceModal();
    } catch (error) {
      console.error("Failed to save place:", error);
      toast.show({
        description: "Failed to save place. Please try again.",
        ...toastErrorConfig,
      });
    }
  };

  const handleChangePlaceName = (value) => {
    setLocalInputs((prev) => ({
      ...prev, // Spread the previous state here as well
      placeName1: value,
    }));
  };

  const handleChange = (key, value) => {
    setLocalInputs((prev) => ({
      ...prev, // This spread operator retains the previous state properties
      [key]: value,
    }));
    setIsEdited(true);
  };

  const handleCloseModal = () => {
    setShownCancelPINModal(false);
  };

  const handleCancelPIN = () => {
    // Uncheck the box when user confirms PIN cancellation
    if (modalLocation === "pickup") {
      dispatch(setInputValue({ key: "pickUpPINRequired", value: false }));
      setWasPickupChecked(false);
    } else if (modalLocation === "delivery") {
      dispatch(setInputValue({ key: "deliveryPINRequired", value: false }));
      setWasDeliveryChecked(false);
    }
    setShownCancelPINModal(false);
  };

  const getDateDifference = (date1, date2) => {
    const diffTime = new Date(date2) - new Date(date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  const nextStep = () => {
    if (!localInputs.deliveryLocation) {
      toast.show({
        description: "Please fill in  delivery location",
        ...toastErrorConfig,
      });
      return;
    }

    const parsedDeliveryLocation = parseLocation(localInputs.deliveryLocation);

    if (
      !parsedDeliveryLocation.street ||
      !parsedDeliveryLocation.city ||
      !parsedDeliveryLocation.state ||
      !parsedDeliveryLocation.zipCode
    ) {
      toast.show({
        description: "Please fill in  full address",
        ...toastErrorConfig,
      });
      return;
    }

    if (
      !localInputs.recipientContactName ||
      !localInputs.recipientContactEmail ||
      !localInputs.recipientContactPhone
    ) {
      toast.show({
        description: "Please fill in recipient information to proceed",
        ...toastErrorConfig,
      });
      return;
    }

    if (
      !localInputs.recipientContactEmail.includes("@") ||
      !localInputs.recipientContactEmail.includes(".")
    ) {
      toast.show({
        description: "Recipient email is invalid",
        ...toastErrorConfig,
      });
      return;
    }

    const isValidRecipientPhone =
      localInputs.recipientContactPhone &&
      localInputs.recipientContactPhone.replace(/\D/g, "").length === 11;

    if (!isValidRecipientPhone) {
      toast.show({
        description: "Please enter a valid 10-digit recipient's phone number",
        ...toastErrorConfig,
      });
      return;
    }

    if (!localInputs.deliveryDate1) {
      toast.show({
        description: "Please enter a delivery date",
        ...toastErrorConfig,
      });
      return;
    }

    if (localInputs.deliveryDate1 < today) {
      toast.show({
        description: "Pickup date can not be in the past",
        ...toastErrorConfig,
      });
      return;
    }

    // Dispatch localInputs to Redux
    Object.entries(localInputs).forEach(([key, value]) => {
      dispatch(setInputValue({ key, value }));
    });

    navigate(
      location.pathname.includes("request-edit")
        ? `/request-edit/preview/${id}`
        : id
        ? `/edit/preview/${id}`
        : "/create/preview"
    );
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const prevStep = () => {
    if (profileOrder && profileOrder.status === "pickedUp") {
      navigate(`/order/${id}`);
    } else {
      navigate(
        location.pathname.includes("request-edit")
          ? `/order/${id}`
          : id
          ? `/edit/2/${id}`
          : "/create/2"
      );
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleCheckboxClick = (location) => {
    if (location === "delivery" && wasDeliveryChecked) {
      setModalLocation("delivery");
      setShownCancelPINModal(true);
    } else {
      // Directly toggle without modal
      if (location === "delivery") {
        dispatch(
          setInputValue({
            key: "deliveryPINRequired",
            value: !deliveryPINRequired,
          })
        );
        setWasDeliveryChecked(!deliveryPINRequired);
      }
    }
  };

  const CancelPINAlert = () => {
    return (
      <AlertDialog
        isOpen={shownCancelPINModal}
        onClose={handleCloseModal}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>
            <Text>
              Cancel {modalLocation === "pickup" ? "pickup" : "delivery"} PIN
            </Text>
          </AlertDialog.Header>
          <AlertDialog.Body>
            <Text>
              By choosing to cancel the PIN code, you acknowledge and accept
              full responsibility for ensuring the
              {modalLocation === "pickup" ? " pickup" : " delivery"}
            </Text>
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <Button.Group space={2}>
              <Button
                style={styles.cancel}
                variant="cancel"
                onPress={handleCloseModal}
              >
                Go back
              </Button>
              <Button
                variant="fatal"
                style={styles.dcButton}
                onPress={handleCancelPIN}
              >
                Cancel PIN
              </Button>
            </Button.Group>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    );
  };

  const handleSelectPlace = (place) => {
    setLocalInputs((prevState) => ({
      ...prevState,
      placeName1: place.placeName,
      deliveryLocation: place.address,
      recipientContactName: place.fullName,
      recipientContactPhone: place.phone,
      recipientContactEmail: place.email,
      checkboxRecipient: false,
    }));
    setIsDropdownVisible(false);
    setIsEdited(false);
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      if (localInputs.placeName1.trim()) {
        const filtered = deliveryPlaces.filter(
          (place) =>
            (place.placeName
              .toLowerCase()
              .includes(localInputs.placeName1.toLowerCase()) ||
              place.address
                .toLowerCase()
                .includes(localInputs.placeName1.toLowerCase())) &&
            place.type === "delivery"
        );

        setFilteredPlaces(filtered);
      }
    }, 300); // Debounce for 300 milliseconds

    return () => {
      clearTimeout(handler);
    };
  }, [localInputs.placeName1, deliveryPlaces]);

  useEffect(() => {
    if (localInputs.placeName1 === "" && isDropdownVisible) {
      setFilteredPlaces(deliveryPlaces);
    }
  }, [localInputs.placeName1, isDropdownVisible]);

  const handleDeletePlace = async (place) => {
    try {
      await dispatch(deletePlace(place.id)).unwrap();
      dispatch(removePlaceById(place.id));
    } catch (error) {
      console.log("error", error);
      toast.show({
        description: "Failed to delete place",
        ...toastErrorConfig,
      });
    }
  };

  return (
    <>
      <View style={styles.container}>
        <Text style={styles.h1}>
          {location.pathname.includes("request-edit")
            ? "Request edit"
            : id
            ? "Edit order"
            : "Create order"}
        </Text>
        <View style={styles.stepper}>
          <Stepper currentStep={3} />
        </View>
        <View>
          <Text
            style={{ fontWeight: "bold", fontSize: 18, marginVertical: 10 }}
          >
            Delivery information
          </Text>
          {deliveryPlaces && deliveryPlaces.length > 0 && (
            <View>
              <FormControl.Label>
                Prefill with saved place (optional)
              </FormControl.Label>
              <View
                style={{
                  backgroundColor: "#F5F5F5", // Light gray background
                  borderRadius: 12,
                }}
              >
                <Input
                  placeholder="Type to find saved place..."
                  value={localInputs.placeName1}
                  onChangeText={(text) => {
                    handleChangePlaceName(text);
                    if (!isDropdownVisible) {
                      setIsDropdownVisible(true); // Ensure dropdown is visible when typing starts
                    }
                  }}
                  onFocus={() => {
                    setIsDropdownVisible(true);
                  }}
                  onBlur={() =>
                    setTimeout(() => {
                      setIsDropdownVisible(false);
                    }, 100)
                  } // Delays hiding dropdown to allow selection
                  InputLeftElement={
                    <View style={styles.leftIcon}>
                      <SearchIcon />
                    </View>
                  }
                />
              </View>
            </View>
          )}
          {isDropdownVisible && (
            <View
              style={styles.dropdownContainer}
              maxHeight={screenHeight * 0.5}
            >
              <ScrollView>
                {filteredPlaces.length > 0 ? (
                  filteredPlaces.map((place) => (
                    <Pressable
                      key={place.id}
                      onPress={() => handleSelectPlace(place)}
                    >
                      <View style={styles.dropdownItem}>
                        <View>
                          <Text style={{ flex: 1, fontWeight: "bold" }}>
                            {place.placeName}
                          </Text>
                          <Text style={{ flex: 1 }}>{place.address}</Text>
                        </View>
                        <Text
                          onPress={() => handleDeletePlace(place)}
                          style={{ color: "blue", alignContent: "center" }}
                        >
                          Delete
                        </Text>
                      </View>
                    </Pressable>
                  ))
                ) : (
                  <View style={styles.dropdownItem}>
                    <Text>No saved places found</Text>
                  </View>
                )}
              </ScrollView>
            </View>
          )}

          <View style={styles.divider} />
          <FormControl.Label>Delivery Location</FormControl.Label>
          <View
            style={[
              styles.dateButtonContainer,
              {
                borderColor: isAddressFocused ? "#1DCE80" : "#D9D9D9",
                backgroundColor: isAddressFocused ? "#E9F8EF" : "#FFFFFF",
                outline: isAddressFocused ? "1px solid #1DCE80" : "none",
              },
            ]}
          >
            <Geo />
            <Autocomplete
              style={{
                width: "100%",
                border: 0,
                paddingLeft: 14,
                outline: "none",
                marginBottom: 8,
                fontSize: 16,
                backgroundColor: isAddressFocused ? "#E9F8EF" : "#FFFFFF",
              }}
              types={["address"]}
              fields={["address_components", "geometry.location"]}
              placeholder={localInputs.deliveryLocation || "Search"}
              onPlaceSelected={(details) => {
                if (details?.geometry?.location) {
                  const location = JSON.parse(
                    JSON.stringify(details?.geometry?.location)
                  );
                  location.title = "Delivery Location";
                  setDeliveryLocationCoordinates(location);
                  handleChange("deliveryLocation", details?.formatted_address);
                }
              }}
              options={{
                types: ["address"],
                componentRestrictions: { country: "us" },
              }}
              onFocus={() => setIsAddressFocused(true)}
              onBlur={() => setIsAddressFocused(false)}
            />
          </View>
          <View style={styles.row}>
            <Checkbox
              value="checkbox"
              isChecked={!deliveryPINRequired}
              onChange={() => handleCheckboxClick("delivery")}
              accessibilityLabel="checkbox"
            />
            <Text style={styles.textContainer}>
              PIN not required at delivery
            </Text>
          </View>
          <Text style={{ fontWeight: "bold", fontSize: 18 }}>
            Recipient information
          </Text>
          <View style={styles.row}>
            <Center>
              <Checkbox
                value="checkbox"
                isChecked={localInputs.checkboxRecipient}
                // defaultIsChecked={localInputs.checkboxRecipient}
                onChange={(state) => {
                  handleChange("checkboxRecipient", state);
                  if (state) {
                    handleChange("recipientContactName", fullName);
                    handleChange("recipientContactEmail", email);
                    handleChange("recipientContactPhone", phone);
                  } else {
                    handleChange("recipientContactName", "");
                    handleChange("recipientContactEmail", "");
                    handleChange("recipientContactPhone", "");
                  }
                }}
                accessibilityLabel="checkbox"
              />
            </Center>

            <Text style={styles.textContainer}>
              Will the account owner be the recipient?
            </Text>
          </View>
          <FormControl.Label>Recipient Name</FormControl.Label>
          <Input
            value={localInputs.recipientContactName}
            onChangeText={(value) => {
              handleChange("recipientContactName", value);
            }}
            type="text"
            isDisabled={localInputs.checkboxRecipient}
            InputLeftElement={
              <View style={styles.leftIcon}>
                <User />
              </View>
            }
            placeholder="Full Name"
          />
          <View style={styles.divider} />
          <FormControl.Label>Recipient Email</FormControl.Label>
          <Input
            value={localInputs.recipientContactEmail}
            onChangeText={(value) => {
              handleChange("recipientContactEmail", value);
            }}
            textContentType="emailAddress"
            keyboardType="email-address"
            autoCapitalize="none"
            type="email"
            isDisabled={localInputs.checkboxRecipient}
            autoCorrect={false}
            autoCompleteType="email"
            InputLeftElement={
              <View style={styles.leftIcon}>
                <Email />
              </View>
            }
            placeholder="Email Address"
          />
          <View style={styles.divider} />
          <FormControl.Label>Recipient Phone Number</FormControl.Label>
          <View
            style={[
              {
                flexDirection: "row",
                alignItems: "center",
                borderRadius: 12,
                borderColor: isRecipientPhoneFocused ? "#1DCE80" : "#D9D9D9",
                backgroundColor: isRecipientPhoneFocused ? "#E9F8EF" : "FAFAFA",
                borderWidth: 1,
                height: 46,
                width: "100%",
                paddingHorizontal: 12,
                outline: isRecipientPhoneFocused ? "1px solid #1DCE80" : "none",
                opacity: localInputs.checkboxRecipient ? 0.5 : 1,
              },
            ]}
          >
            <View style={{ marginRight: 10 }}>
              <Phone />
            </View>
            <Text>+1 </Text>
            <PhoneInput
              value={localInputs.recipientContactPhone}
              onChange={(value) => {
                handleChange("recipientContactPhone", value);
              }}
              type="tel"
              maxLength="14"
              placeholder="(222) 333-4455"
              defaultCountry="US"
              style={{
                paddingLeft: 0,
                outline: "none",
                flex: 1,
                borderWidth: 0,
                backgroundColor: isRecipientPhoneFocused ? "#E9F8EF" : "white",
                fontSize: 16,
              }}
              onFocus={() => setIsRecipientPhoneFocused(true)}
              onBlur={() => setIsRecipientPhoneFocused(false)}
              disabled={localInputs.checkboxRecipient}
            />
          </View>
          <FormControl.Label style={{ marginTop: 20 }}>
            Remember this info if you place orders frequently to deliver here
          </FormControl.Label>
          <View style={{ width: 127 }}>
            <Button
              isDisabled={
                !active ||
                !isEdited ||
                !(deliveryLocation || localInputs.deliveryLocation) ||
                !localInputs.recipientContactName ||
                !localInputs.recipientContactEmail ||
                !localInputs.recipientContactPhone
              }
              onPress={handleOpenSavePlaceModal}
              variant="outline"
            >
              Save place
            </Button>
          </View>
          <Text
            style={{ fontWeight: "bold", fontSize: 18, marginVertical: 10 }}
          >
            Desired delivery date
          </Text>
          {/* <View style={styles.divider} /> */}
          <FormControl.Label>Delivery Date</FormControl.Label>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <View>
              <View style={styles.inputBorder}>
                <input
                  style={{
                    borderWidth: 0,
                    color: "#818181",
                    fontSize: 16,
                    backgroundColor: "transparent",
                  }}
                  onChange={(e) => {
                    const selectedDate = e.target.value;
                    const diffDays = getDateDifference(
                      localInputs.pickUpDate1,
                      selectedDate
                    );
                    if (diffDays > 12) {
                      //  handleChange("pickUpDate1", "");
                      toast.show({
                        description:
                          "Pickup date must be within 12 days before delivery",
                        ...toastWarningConfig,
                      });
                      return;
                    }
                    setLocalInputs((prev) => ({
                      ...prev,
                      ["deliveryDate1"]: selectedDate,
                    }));
                  }}
                  value={localInputs.deliveryDate1 || ""}
                  type="date"
                  name="deliveryDate1"
                  min={localInputs.pickUpDate1}
                  max={addDays(localInputs.pickUpDate1, 12)}
                />
              </View>
            </View>
            <View style={styles.checkbox}>
              <Checkbox
                value="checkbox"
                isChecked={localInputs.flexibleDeliveryDate}
                onChange={(state) =>
                  setLocalInputs((prev) => ({
                    ...prev,
                    ["flexibleDeliveryDate"]: state,
                  }))
                }
                accessibilityLabel="checkbox"
              />
              <Popover
                trigger={(triggerProps) => {
                  return (
                    <Pressable {...triggerProps}>
                      <View style={{ flexDirection: "row" }}>
                        <Text style={styles.textContainer}>Flexible Date </Text>
                        <IoAlertCircleOutline
                          size={18}
                          style={{ cursor: "pointer" }}
                        />
                      </View>
                    </Pressable>
                  );
                }}
                placement="top"
              >
                <Popover.Content maxWidth="350px">
                  <Popover.Arrow bg="gray.100" />
                  <Popover.CloseButton />
                  <Popover.Header bg="gray.100">
                    Flexible Delivery Date
                  </Popover.Header>
                  <Popover.Body bg="gray.100">
                    Choosing a flexible date allows the carrier to plan their
                    route, potentially leading to a more cost-effective
                    transportation offer for you.
                  </Popover.Body>
                </Popover.Content>
              </Popover>
            </View>
          </View>
          <FormControl.Label style={{ marginTop: 4 }}>
            Please ensure the delivery date is within 12 days after the pickup
            date.
          </FormControl.Label>

          <View style={styles.emptySpace} />
          <BottomNavigator
            nextOnPress={nextStep}
            nextText={"Next"}
            prevOnPress={prevStep}
            prevText={
              profileOrder && profileOrder.status === "pickedUp"
                ? "Cancel"
                : "Previous"
            }
          />
        </View>
      </View>
      <CancelPINAlert />
      <AlertDialog
        isOpen={isModalVisible}
        onClose={handleCloseSavePlaceModal}
        useRNModal={true}
      >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>Name Your Delivery Place</AlertDialog.Header>
          <AlertDialog.Body>
            <Input
              placeholder="Enter a name for the place"
              value={localInputs.placeName1}
              onChangeText={handleChangePlaceName}
              autoFocus
            />
            <View style={{ height: 20 }}></View>
            <Text>{localInputs.deliveryLocation}</Text>
            <View my={2}>
              <Divider bg="gray.300" thickness="1" />
            </View>
            <Text>{localInputs.recipientContactName}</Text>
            <View my={2}>
              <Divider bg="gray.300" thickness="1" />
            </View>
            <Text>{localInputs.recipientContactEmail}</Text>
            <View my={2}>
              <Divider bg="gray.300" thickness="1" />
            </View>
            <Text>{formatPhone(localInputs.recipientContactPhone)}</Text>
          </AlertDialog.Body>
          <AlertDialog.Footer>
            <View style={styles.buttonsRow}>
              <Button
                style={styles.cancelButton}
                variant="cancel"
                onPress={handleCloseSavePlaceModal}
              >
                Cancel
              </Button>
              <Button
                style={styles.confirmButton}
                onPress={handleConfirmSavePlaceModal}
              >
                Confirm
              </Button>
            </View>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    </>
  );
}
